import React, { useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory, faSearch, faFilter, faMobileAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import logoImage from "../logo/logo.jpeg";

const pageSize = 8;

function PrintComponent({ prescriptionData, onClose }) {
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(prescriptionData.length / pageSize);

  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = prescriptionData.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      setCurrentPage(newPage);
    }
  };
  console.log("prescriptionData", prescriptionData);

  const handlePdf = async () => {
    const html2canvasOptions = {
      scale: 2,
      logging: false,
      allowTaint: true,
    };

    const jsPDFOptions = {
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    };

    const doc = new jsPDF(jsPDFOptions);

    for (let i = 0; i < totalPages; i++) {
      // Set current page before capturing
      setCurrentPage(i);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for page to update

      const capture = document.querySelector(`#bill`);
      const canvas = await html2canvas(capture, html2canvasOptions);
      const imgData = canvas.toDataURL("image/png");

      const imageWidth = 180;
      const imageHeight = (canvas.height * imageWidth) / canvas.width;

      const marginLeft = (doc.internal.pageSize.width - imageWidth) / 2;

      if (i !== 0) {
        doc.addPage(); // Add new page for subsequent pages
      }

      doc.addImage(
        imgData,
        "PNG",
        marginLeft,
        10, 
        imageWidth,
        imageHeight
      );
    }

    doc.save("bill.pdf");
  };

  const getOrdinal = (number) => {
    const suffixes = [
      "th",
      "st",
      "nd",
      "rd",
      "th",
      "th",
      "th",
      "th",
      "th",
      "th",
    ];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  let grandTotal = 0;

  const patientData = prescriptionData.length > 0 ? prescriptionData[0] : null;

  return (
    <div>
      <div className="mb-2" style={{fontFamily:"Arial"}}>
        <style>
          {`
          .bill {
            border: 1px solid grey;
            background-image: url('../Assets/mrprint.jpg');
            background-size: 205mm 290mm;
            background-repeat: no-repeat;
            background-position: center;
            height: 290mm;
            width: 205mm;
            position: relative; 
          }
          .registration-content {
            position: absolute;
            top: 7%;       
          }
          .content{
            position: absolute;
            width:95%;
            left: 5%; 
          }
          .heading{    
            margin-top:44px;
          }
          .text{
            font-size: 16px;
          }
          .head img {
            
            width: 150px;
            height: 150px;
          border-radius:50%;
          }    
          .demo1{
            position: absolute;
            font-size: 30px;
            margin-left:100px;
          }
         .demo{
          margin-right:80px;
          font-size:12px;
         }
          
          .invoice{
            font-size: 25px;
            margin-bottom: 12px;
          }
          
          @media only screen and (min-width: 1024px)  {
            .heading {
             
            }
          }

         
          @media (max-width: 600px) {
            .bill {
              border: 1px solid grey;
              background-image: url('../Assets/mrprint.jpg');
              background-size: 100% 100%; /* Cover the entire container */
              background-repeat: no-repeat;
              background-position: center;
              height: 90vh;
              width: 90vw;
            }
          
            .registration-content {
              position: absolute;        
              top: 7%; 
             width: 100%;      
            }
            .content{
              position: absolute;
              left: 6%; 
            }
          .text{
            font-size: 7px;
          }
          .heading{      
            margin-top:18px;
          }
          .demo1{
            position: absolute;
            font-size: 15px;
            margin-left:20px;
          }
          .demo{
            
            font-size: 6.5px;
          }
          
          .head img {
            width: 70px; 
            height: 70px; 
            border-radius:50%;
          } 
          .invoice{
            font-size: 10px;
            margin-bottom: 5px;
          }         
          }     
                    
          
        `}
        </style>
        <button className="btn btn-primary" onClick={handlePdf}>
          Download
        </button>
      </div>
      <div>
        <div id={`bill`} className="bill border border-gray-300 rounded-lg ">
      
          <div className="registration-content">
          <div className="head"
            style={{display:'flex', alignItems:'center'}}
          >
            <img className="img"
              src={logoImage}
              alt="Profile"
            />
            <div className="heading text-gray-700">
              <h2  className="demo1 font-bold"
                style={{
                  color: "rgb(0,164,155)"                 
                }}
              >
                LINGA DENTAL CARE
              </h2>
              <div className="demo">
              <p >
                Sri Meenakshi Complex, Alaguraja Nagar, Alagar Kovil Road,
                Madurai-625007
              </p>
              <div className="text-center mt-2" >
      <span className="icon-text">
        <FontAwesomeIcon icon={faMobileAlt} style={{color:'teal'}}/>
        <span>{"  "} 99940891117</span>
      </span> {"  "}
      <span className="icon-text">{"  "}
        <FontAwesomeIcon icon={faEnvelope} style={{color:'teal'}}/>
        <span>{"  "} lingadentalcare@gmail.com</span>
      </span>
    </div>
              </div>
             
            </div>
          </div>
<div className="content">
<div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="text">
                {patientData && (
                  <div className="text-start">
                    <p>
                      <b>To</b>
                    </p>
                    <p> {patientData.patientname}</p>

                    <p>
                      {" "}
                      {patientData.age}/{patientData.gender}
                    </p>
                    <p>Madurai, Tamil Nadu</p>
                    <p> PH No- {patientData.mobile_number}</p>
                  </div>
                )}
              </div>
              <div className="text">
                <p className="font-bold" style={{ color: "rgb(0,164,155)" }}>
                  Dr. R. VINOTH KUMAR,<span>BDS.,</span>
                </p>
                <p>Dental Surgeon</p>
                <p>Reg No:26424</p>
              <br/>
                <p><b>UHID:</b> {patientData.uhid}</p>
                <p> <b>Patient Name: </b>{patientData.patientname}</p>

              </div>
            </div>

            <div
              className="invoice text-center font-bold"
              style={{
                color: "teal",
              }}
            >
              <h2>Invoice</h2>
            </div>

            <div className="mb-4">
              <table className="table-auto w-full border-collapse border border-black-600">
                <thead style={{ backgroundColor: "teal" }}>
                  <tr>
                    <th className="border text-white text-center border-black-600 py-1">
                      S.No
                    </th>
                    <th className="border text-white text-center border-black-600 px-2 py-1">
                      Description
                    </th>
                    <th className="border text-white text-center border-black-600 py-1">
                      Cost
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item, index) => {
                    const dateWithoutTime = item.date.split(",")[0].trim();
                    const cost = parseFloat(item.treatmentFees) || 0;
                    return (
                      <tr key={index}>
                        <td className="border text-center border-black-600 py-1">
                          {startIndex + index + 1}
                        </td>
                        <td className="border border-black-600 py-1">
                          <div className="flex flex-col items-start">
                            <div className="">
                              {startIndex + index === 0
                                ? "First"
                                : startIndex + index === 1
                                ? "Second"
                                : getOrdinal(startIndex + index + 1)}{" "}
                              Appointment on {dateWithoutTime}
                            </div>
                            <div >{item.treatmentgiven}</div>
                          </div>
                        </td>
                        <td className="border text-center border-black-600 px-2 py-1">
                          {item.treatmentFees}
                        </td>
                      </tr>
                    );
                  })}

                  {currentPage === totalPages - 1 && (
                    <tr>
                      <td
                        className="border text-black text-center border-black-600 py-1"
                        colSpan="2"
                      >
                        Grand Total
                      </td>
                      <td className="border text-center border-black-600 px-2 py-1">
                        {prescriptionData
                          .reduce(
                            (acc, item) =>
                              acc + parseFloat(item.treatmentFees) || 0,
                            0
                          )
                          .toFixed(2)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
</div>
           
          </div>
        </div>
      </div>
      <div className="flex justify-evenly mt-2 mb-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 0}
        >
          Previous
        </button>

        <div>{`Page ${currentPage + 1} of ${totalPages}`}</div>

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages - 1}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default PrintComponent;