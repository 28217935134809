import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import config from "../config";
import dentalImage from "../logo/try.jpg";
import CheckboxApp from "./patientregistration";
import Doctor from "./doctor";
import Appointment from "./appointment";
import logoImage from "../logo/logo.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import HomePage1 from './responsivepage';

const HomePage = () => {
  const [showContent, setShowContent] = useState(true);
  const [currentImage, setCurrentImage] = useState(1);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null); // State to manage active component
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [showToggleButton, setShowToggleButton] = useState(true); 


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((currentImage) => (currentImage % 3) + 1);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  const handleComponentClick = (component) => {
    setActiveComponent(component);
    if (
      component === "home"  ||
      component === "registration" ||
      component === "doctor" ||
      component === "appointment"
    ) {
      setShowToggleButton(true);
      setShowContent(true); // Ensure content is shown when registration or doctor is clicked
    }
  };

  const handleSearchClick =()=>{
    setShowToggleButton(false);
  }

  const handleHistoryButtonClick = () => {
    setShowToggleButton(false);
    // Perform any other actions you need when the "History" button is clicked
  };
  const handlesubmit =()=>{
    setShowToggleButton(false);
  }
  const handleToggleButtonClick = () => {
    setShowToggleButton(true);
  };
 
  useEffect(() => {
    // Retrieve user data from local storage on component mount
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUser(userData);
    }
  }, []);

  const handleLogout = async () => {
    const logoutEventData = {
      userId: user.user.user_id,
      userFirstName: user.user.user_first_name,
      userLastName: user.user.user_last_name,
      userRole: user.user.user_role,
    };
    try {
      const response = await fetch(`${config.apiUrl}/user/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(logoutEventData),
      });

      const result = await response.json();

      if (response.status === 200) {
        localStorage.removeItem("user");
        setUser(null); // Reset user state
        history.push("/");
        window.location.reload();
      } else {
        console.error("Error logging logout event:", result.message);
      }
    } catch (error) {
      console.error("Error logging logout event:", error.message);
    }
  };

  return (
    <div   style={{
      backgroundColor: showContent ? "rgb(40,204,205)" : "white",
      transition: "background-color 0.5s ease",
    }}>
      <style>
        {`
          @keyframes fadeIn {
            0% { opacity: 0; }
            100% { opacity: 1; }
          }

          .fadeIn { animation: fadeIn 0.5s ease-in-out forwards; }

          @keyframes scaleUp {
            0% { transform: scale(0); opacity: 0; }
            100% { transform: scale(1); opacity: 1; }
          }

          .scaleUp { animation: scaleUp 1s ease-in-out forwards; }
        `}
      </style>

      <div className="flex text-white min-h-screen relative"   style={{
            backgroundColor: showContent ? "rgb(40,204,205)" : "white",
            transition: "background-color 0.5s ease",
          }}>
        <div
          className="w-3/4 relative"
          style={{
            backgroundImage: `url('../Assets/new.jpg')`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
           
              transition: "background-color 0.5s ease",
          }}
        >
          {!activeComponent && (
            <img
              src={dentalImage}
              alt="Attractive Look"
              className={`absolute inset-0 w-full h-full 
              }`}
              style={{
                backgroundColor: showContent ? "rgb(40,204,205)" : "rgb(40,204,205)",

                opacity: imageLoaded ? 1 : 0,
                transition: "opacity 0.5s ease",
                maskImage:
                  "linear-gradient(to left, transparent, black 20%, black 90%, transparent)",
                WebkitMaskImage:
                  "linear-gradient(to left, transparent, black 20%, black 80%, transparent)",
              }}
              onLoad={handleImageLoad}
            />
          )}
          {activeComponent === "registration" && <CheckboxApp  onHistoryButtonClick={handleHistoryButtonClick} 
              onToggleButtonClick={handleToggleButtonClick}  onPrintButtonClick={handleSearchClick}/>}
          {activeComponent === "doctor" && <Doctor  onHistoryButtonClick={handleHistoryButtonClick} 
              onToggleButtonClick={handleToggleButtonClick}  onPrintButtonClick={handlesubmit}/>}
          {activeComponent === "appointment" && <Appointment />}
          {activeComponent === "home" &&  <img
              src={dentalImage}
              alt="Attractive Look"
              className={`absolute inset-0 w-full h-full 
              }`}
              style={{
                backgroundColor: showContent ? "rgb(40,204,205)" : "rgb(40,204,205)",

                opacity: imageLoaded ? 1 : 0,
                transition: "opacity 0.5s ease",
                maskImage:
                  "linear-gradient(to left, transparent, black 20%, black 90%, transparent)",
                WebkitMaskImage:
                  "linear-gradient(to left, transparent, black 20%, black 80%, transparent)",
              }}
            />}
        </div>

        <div className="flex justify-center items-center relative font-serif"
        >
          <div>
            <div
              className={`absolute top-0 right-0 pt-4 text-2xl font-bold whitespace-nowrap ${
                showContent ? "rgb(40,204,205)": "text-gray-500"
              }`}
              style={{
                marginRight: "-310px",
                // color:"rgb(40,204,205)"
                color: showContent ? "white" : "rgb(40,204,205)",
              }}
            >
              <h2 style={{ fontWeight: "bold", fontSize: "28px"  }} 
              >
                LINGA DENTAL CARE
              </h2>
              <p style={{ fontSize: "16px", marginLeft: "64px" }}>
                ALWAYS SMILE BRIGHT
                <FontAwesomeIcon
                  onClick={handleLogout}
                  className="ml-5 cursor-pointer"
                  icon={faSignOutAlt}
                />
                {/* {user ? ` ${user.user.user_role}` : "Guest"} */}
              </p>
            </div>

            <div
              className={`absolute w-full flex justify-around ${
                showContent ? "opacity-100" : "opacity-0"
              }`}
            >
           <div
  className={`cursor-pointer rounded-full p-5 h-20 w-20 flex justify-center items-center ${
    showContent ? "scaleUp" : ""
  } ${showContent ? "fadeIn" : ""}`}
  style={{
    marginTop: "90px",
    transitionDelay: showContent ? "0.5s" : "0s",
    marginRight: "-180px",
    marginLeft: "400px",
    boxShadow: "0px 0px 20px 5px rgba(255, 255, 255, 1)",
    background:  "linear-gradient(to bottom right, #87CEEB, #008080)",
      border: activeComponent === "appointment" ? "2px solid white" : "none",
      transformOrigin: "center",
      transition: "transform 0.3s",

  }}
  onClick={() => handleComponentClick("appointment")}
  onMouseEnter={() => setShowContent(true)}
>
  <p>Appointment</p>
</div>

<div
      className={`cursor-pointer bg-sky-400 rounded-full p-5 h-20 w-20 flex justify-center items-center ${
        showContent ? "scaleUp" : ""
      } ${showContent ? "fadeIn" : ""}`}
      style={{
        marginBottom: "120px",
        transitionDelay: showContent ? "1s" : "0s",
        marginRight: "-50px",
        boxShadow: "0px 0px 20px 5px rgba(255, 255, 255, 1)",
        background: "linear-gradient(to bottom right, #87CEEB, #008080)",
        border: activeComponent === "doctor" ? "2px solid white" : "none",
        transformOrigin: "center",
        transition: "transform 0.3s",
      }}
      onClick={() => handleComponentClick("doctor")}
      onMouseEnter={() => setShowContent(true)}
    >
      <p>Doctor Module</p>
    </div>
              <div
                className={`cursor-pointer rounded-full p-5 h-20 w-20 flex justify-center items-center ${
                  showContent ? "scaleUp" : ""
                } ${showContent ? "fadeIn" : ""}`}
                style={{
                  marginTop: "-115px",
                  transitionDelay: showContent ? "1.5s" : "0s",
                  marginLeft: "-40px",
                  boxShadow: "0px 0px 20px 5px rgba(255, 255, 255, 1)",
                  transformOrigin: "center",
                  transition: "transform 0.3s",
                  background:
                    "linear-gradient(to bottom right, #87CEEB, #008080)",
                    border: activeComponent === "registration" ? "2px solid white" : "none"
                }}
                onClick={() => handleComponentClick("registration")}
                onMouseEnter={() => setShowContent(true)}
              >
                <p>Registration</p>
              </div>
              <div
                className={`cursor-pointer hover:bg-red-400 rounded-full p-5 h-20 w-20 flex justify-center items-center ${
                  showContent ? "scaleUp" : ""
                } ${showContent ? "fadeIn" : ""}`}
                style={{
                  marginTop: "-180px",
                  transitionDelay: showContent ? "2s" : "0s",
                  boxShadow: "0px 0px 20px 5px rgba(255, 255, 255, 1)",
                  background:
                    "linear-gradient(to bottom right, #87CEEB, #008080)",
                    border: activeComponent === "home" ? "2px solid white" : "none",
                    transformOrigin: "center",
                    transition: "transform 0.3s",
                }}
                onClick={() => handleComponentClick("home")}
                onMouseEnter={() => setShowContent(true)}
              >
                <p>Home</p>
              </div>
            </div>

            <div className="container mx-auto text-center relative">
            {showToggleButton && (
          <button>
            
          </button>
        )}
              <button
                className="text-white rounded-full hover:bg-blue-600 relative"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%  50%",
                  background:
                    "linear-gradient(to bottom right, #87CEEB, #008080)",
                    boxShadow: "0px 0px 20px 5px rgba(255, 255, 255, 1)",
                    transition: "width 0.5s, border-radius 0.5s",
                  position: "absolute",
                  top: "10px",
                  left: "250px",
                  transform: "translate(-50%, -50%)",
                }}
                onClick={toggleContent}
              >
                {showContent ? (
                  <img
                    style={{  width: "100px",
                    height: "100px",
                    borderRadius: "50%", }}
                    src={logoImage}
                    alt="Logo"
                  />
                ) : (
                  "Click Me"
                )}
              </button>
            </div>
            <div
              className={`absolute bottom-0 right-0 pt-4 text-2xl font-bold whitespace-nowrap ${
                showContent ? "rgb(40,204,205)": "text-gray-500"
              }`}
              style={{
                marginRight: "-310px",
                // color:"rgb(40,204,205)"
                color: showContent ? "white" : "rgb(40,204,205)",
              }}
            >
            
              <p style={{ fontSize: "14px", marginLeft:'10px'}}>
               Sri Meenakshi Complex, Alaguraja Nagar,
              
                {/* {user ? ` ${user.user.user_role}` : "Guest"} */}
              </p>
              <p style={{ fontSize: "14px",marginLeft:'10px', marginBottom:'20px'}}>Alagar Kovil Main Road, Madurai -625007.</p>
              {/* <FontAwesomeIcon
                  onClick={handleLogout}
                  className="ml-5 cursor-pointer"
                  icon={faSignOutAlt}
                /> */}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};


const MyComponent = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 992);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 992);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isTabletOrDesktop = window.innerWidth > 810 && window.innerWidth < 1024;

  return (
    <div >
      {isDesktop || isTabletOrDesktop ? (
        <div className="desktop-layout">
          <HomePage />
        </div>
      ) : (
        <div className="mobile-layout">
          <HomePage1 />
        </div>
      )}
    </div>
  );
};

export default MyComponent;
