import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { format } from "date-fns";
import config from "../config";
import _ from "lodash";
import moment from "moment";
import { DatePicker } from "antd";
import logo from "../../src/logo/logo1.png";
import { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory, faTimes,faSearch } from "@fortawesome/free-solid-svg-icons";
import { text } from "@fortawesome/fontawesome-svg-core";

const LoadingSpinner = () => (
  <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center flex items-center bg-blue-500 p-2 rounded-lg shadow-lg">
    <div className="animate-spin rounded-full border-t-4 bg-violet-950 border-opacity-50 h-8 w-8 mx-2"></div>
    <div className="text-white font-bold">Loading...</div>
  </div>
);

const CheckboxApp = ({onHistoryButtonClick, onToggleButtonClick,onPrintButtonClick}) => {
  const isMobile = window.innerWidth <= 767;
  const componentRef = useRef();
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [fatherName, setFatherName] = useState("");
  const [dob, setDob] = useState("");
  const [age, setAge] = useState("");
  const [ageUnit, setAgeUnit] = useState("years");
  const [gender, setGender] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [aadhaarNumber, setAadhaarNumber] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [cityVillage, setCityVillage] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("Tamil Nadu");
  const [district, setDistrict] = useState("");
  const [registrationDate, setRegistrationDate] = useState(getCurrentDate());

  const [uhidInput, setUhidInput] = useState("");
  const [mobileNumberInput, setMobileNumberInput] = useState("");

  const [submittedData, setSubmittedData] = useState(null);
  const [editupdate, setEditUpdate] = useState(null);

  const [isFirstTimeRegistration, setIsFirstTimeRegistration] = useState(true);
  const [editedData, setEditedData] = useState({});

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showRedBorders, setShowRedBorders] = useState(false);
  const [showRedBorders1, setShowRedBorders1] = useState(false);

  const [showSubmit, setShowSubmit] = useState(true);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showUpdateSuccessMessage, setShowUpdateSuccessMessage] =
    useState(false);

  const [isallare, setIsAllAre] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  const [idCardData, setIdCardData] = useState(null);
  const [showIdCard, setShowIdCard] = useState(false);
  const printRef = useRef();
  const [patientData, setPatientData] = useState({});
  const [showPatientsTable, setShowPatientsTable] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showInputs, setShowInputs] = useState(false);
  const [multiplePatients, setMultiplePatients] = useState(null);
  const [tablehide, setTablehide] = useState(false);
  const [selectedPatientUHID, setSelectedPatientUHID] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [patientHistory, setPatientHistory] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [uhidOptions, setUHIDOptions] = useState([]);
  const [mobileNumberOptions, setMobileNumberOptions] = useState([]);



  function getCurrentDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  const allDistricts = {
    "Tamil Nadu": [
      "Ariyalur",
      "Chengalpattu",
      "Chennai",
      "Coimbatore",
      "Cuddalore",
      "Dharmapuri",
      "Dindigul",
      "Erode",
      "Kallakurichi",
      "Kanchipuram",
      "Kanyakumari",
      "Karur",
      "Krishnagiri",
      "Madurai",
      "Mayiladuthurai",
      "Nagapattinam",
      "Namakkal",
      "Nilgiris",
      "Perambalur",
      "Pudukkottai",
      "Ramanathapuram",
      "Ranipet",
      "Salem",
      "Sivaganga",
      "Tenkasi",
      "Thanjavur",
      "Theni",
      "Thoothukudi",
      "Tiruchirappalli",
      "Tirunelveli",
      "Tirupathur",
      "Tiruppur",
      "Tiruvallur",
      "Tiruvannamalai",
      "Vellore",
      "Viluppuram",
      "Virudhunagar",
    ],
  };

  useEffect(() => {
    if (dob) {
      const birthDate = new Date(dob);
      const currentDate = new Date();

      switch (ageUnit) {
        case "days":
          const ageDifferenceInDays = Math.floor(
            (currentDate - birthDate) / (1000 * 60 * 60 * 24)
          );
          setAge(ageDifferenceInDays);
          break;
        case "months":
          const ageDifferenceInMonths =
            currentDate.getMonth() -
            birthDate.getMonth() +
            12 * (currentDate.getFullYear() - birthDate.getFullYear());
          setAge(ageDifferenceInMonths);
          break;
        case "years":
        default:
          const ageDifferenceInYears =
            currentDate.getFullYear() - birthDate.getFullYear();
          setAge(ageDifferenceInYears);
          break;
      }
    }
  }, [dob, ageUnit]);
  useEffect(() => {
    const delay = 1000; // Adjust the delay according to your preferences

    const timeoutId = setTimeout(async () => {
      try {
        const response = await fetch(
          `${config.apiUrl}/registration/patient/namesearch?name=${searchTerm}`
        );
        if (response.ok) {
          const searchResults = await response.json();
          setSearchResults(searchResults);
        } else {
          console.error("Failed to fetch search results");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }, delay);

    // Clear the previous timeout to prevent multiple API calls
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  useEffect(() => {
    const currentDate = getCurrentDate();
    setRegistrationDate(currentDate);
  }, []);

  useEffect(() => {
    fetchUHIDs();
  }, []);
  const fetchUHIDs = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/doctor/patientregistration/listuhids`
      );
      console.log("response", response);
      setUHIDOptions(response.data);
    } catch (error) {
      console.error("Error fetching UHIDs:", error);
    }
  };

  useEffect(() => {
    fetchMobileNumbers();
  }, []);
  const fetchMobileNumbers = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/doctor/patientregistration/listmobilenumber`
      );
      console.log("response", response);
      setMobileNumberOptions(response.data);
    } catch (error) {
      console.error("Error fetching UHIDs:", error);
    }
  };
  

  const fetchPatientHistory = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/registration/patient/all`);
      if (!response.ok) {
        throw new Error("Error fetching patient history");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching patient history:", error);
      throw error;
    }
  };
  const filterData = () => {
    return patientHistory.filter((item) => {
      const itemDate = moment(item.time).startOf("day");
      const isWithinDateRange =
        (!fromDate ||
          itemDate.isSameOrAfter(moment(fromDate).startOf("day"))) &&
        (!toDate || itemDate.isSameOrBefore(moment(toDate).endOf("day")));

      const includesSearchTerm =
        !searchTerm ||
        `${item.Title} ${item.first_Name} ${item.last_Name}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.Mobile_number.includes(searchTerm);

      return isWithinDateRange && includesSearchTerm;
    });
  };

  useEffect(() => {
    const filtered = filterData();
    setFilteredPatients(filtered);
  }, [fromDate, toDate, searchTerm, patientHistory]);

  const handleHistoryButtonClick = async () => {
    try {
      const data = await fetchPatientHistory();
      setPatientHistory(data);
      setShowHistory(true);
      onHistoryButtonClick();
    } catch (error) {
      console.error("Failed to fetch patient history:", error);
    }
  };
  const handleFromDateChange = (date, dateString) => {
    setFromDate(dateString);
  };

  const handleToDateChange = (date, dateString) => {
    setToDate(dateString);
  };
  const handleTitleChange = (e) => {
    const newTitle = e.target.value;

    if (isFirstTimeRegistration) {
      setTitle(newTitle);
    } else {
      setEditedData({ title: newTitle });
    }
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value.replace(/[^A-Za-z ]/g, ""));
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value.replace(/[^A-Za-z ]/g, ""));
  };

  const handleFatherNameChange = (event) => {
    setFatherName(event.target.value.replace(/[^A-Za-z ]/g, "").trim());
  };

  const handleDobChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const currentDate = new Date();

    // Check if the selected date is beyond the current date
    if (selectedDate > currentDate) {
      // If it is, set the date to the current date
      setDob("");
      setAgeUnit("years");
      setAge(0);
    } else {
      setDob(event.target.value);

      // Calculate age based on the selected date
      const ageDifferenceInDays = Math.floor(
        (currentDate - selectedDate) / (1000 * 60 * 60 * 24)
      );
      const ageDifferenceInMonths =
        currentDate.getMonth() -
        selectedDate.getMonth() +
        12 * (currentDate.getFullYear() - selectedDate.getFullYear());
      const ageDifferenceInYears =
        currentDate.getFullYear() - selectedDate.getFullYear();

      // Automatically set the appropriate age unit
      if (ageDifferenceInDays < 30) {
        setAgeUnit("days");
        setAge(ageDifferenceInDays);
      } else if (ageDifferenceInMonths < 12) {
        setAgeUnit("months");
        setAge(ageDifferenceInMonths);
      } else {
        setAgeUnit("years");
        setAge(ageDifferenceInYears);
      }
    }
  };

  const handleStreet1Change = (event) => {
    setStreet1(event.target.value);
  };

  const handleStreet2Change = (event) => {
    const value = event.target.value.replace(/[^A-Za-z ]/g, "");
    setStreet2(value);
  };

  const handleCityVillageChange = (event) => {
    setCityVillage(event.target.value);
  };

  const handleAgeChange = (event) => {
    const inputAge = parseInt(event.target.value, 10);

    if (!isNaN(inputAge) && inputAge >= 0) {
      setAge(inputAge);
    } else {
      setAge(0);
    }
  };

  const handleAgeUnitChange = (event) => {
    const selectedAgeUnit = event.target.value;
    setAgeUnit(selectedAgeUnit);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleDistrictChange = (event) => {
    setDistrict(event.target.value);
  };
  const districtsOptions = allDistricts[state] || [];

  const handleStateChange = (event) => {
    setState(event.target.value);
    setDistrict("");
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const displayAlert = (message) => {
    setAlertMessage(message);
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, 2000);
  };

  const resetFormFields = () => {
    setUhidInput("");
    setMobileNumberInput("");
    setTitle("");
    setFirstName("");
    setLastName("");
    setFatherName("");
    setDob("");
    setAge("");
    setAgeUnit("years");
    setGender("");
    setStreet1("");
    setStreet2("");
    setCityVillage("");
    setMobileNumber("");
    setDistrict("");
    setState("Tamil Nadu");
    setCountry("India");
    setAadhaarNumber("");
    setIsAllAre(false);
    setShowUpdateSuccessMessage(false);
  };
  const handleClose = () => {
    setShowIdCard(false);
  };

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: "ID Card",
  // });


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "ID Card",
    pageStyle: `
      @page {
        size: 3.5in 2in; 
        margin: 0; /* No margins */
      }
      @media print {
        body {
         
          margin: 0; /* No body margins */
        }
      }
    `,
  });

  const handleSubmit = () => {
    if (
      !title ||
      !firstName.trim() ||
      !lastName.trim() ||
      !fatherName.trim() ||
      !age ||
      !gender ||
      !aadhaarNumber ||
      !street1.trim() ||
      !street2.trim() ||
      !cityVillage.trim() ||
      !district.trim() ||
      !state.trim() ||
      !country.trim() ||
      !mobileNumber.trim()
    ) {
      setShowRedBorders(true);

      setTimeout(() => {
        setShowRedBorders(false);
      }, 2000);

      return;
    }
    if (!/^[6-9]\d{9}$/.test(mobileNumber)) {
      setShowAlert(true);
      setAlertMessage("Please enter a valid mobileNumber");

      setTimeout(() => {
        setShowAlert(false);
        setSubmitDisabled(false);
      }, 2000);

      return;
    }

    if (aadhaarNumber.length !== 12) {
      setAlertMessage("Aadhar number must be 12 digits");
      setShowRedBorders(true);
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
        setShowRedBorders(false);
        setSubmitDisabled(false);
      }, 2000);

      return;
    }

    setSubmitDisabled(true);

    const formData = {
      Title: title,
      first_Name: firstName,
      last_Name: lastName,
      father_name: fatherName,
      date_of_birth: dob || null,
      age: age,
      age_Unit: ageUnit,
      gender: gender,
      Mobile_number: mobileNumber,
      aadhar_number: aadhaarNumber,
      street1: street1,
      street2: street2,
      city_village: cityVillage,
      district: district,
      state: state,
      country: country,
      registration_date: registrationDate,
    };

    fetch(`${config.apiUrl}/registration/patient`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          console.log(response);
          if (response.status === 400) {
            throw new Error("Aadhar number already exists");
          } else {
            throw new Error(`Server error: ${response.status}`);
          }
        }
        return response.json();
      })
      .then((data) => {
        const { uhid } = data;

        if (uhid) {
          const receivedUHID = uhid;
          console.log("id", receivedUHID);

          const idCard = {
            uhid: receivedUHID,
            name: `${title} ${firstName} ${lastName}`,
            age: `${age} ${ageUnit}`,
            gender: `${gender}`,
            district: `${district}`,
            mobileNumber: `${mobileNumber}`,
          };

          setIdCardData(idCard);
          console.log("id card", idCard);

          const submittedData = {
            uhid: uhid,
            dateTime: new Date().toLocaleString(),
            ...formData,
          };

          setSubmittedData(submittedData);
          setSubmitting(true);
          setTimeout(() => {
            setShowSuccessMessage(true);
          }, 2000);

          setTimeout(() => {
            resetFormFields();
            setSubmitDisabled(false);
            setShowSuccessMessage(false);
            setSubmitting(false);
            setShowIdCard(true);
          }, 4000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        if (error instanceof Error) {
          if (error.response && error.response.status === 400) {
            setAlertMessage("Aadhar number already exists");
          } else {
            console.error("Server response:", error.message);
            setAlertMessage(error.message);
          }
          setShowAlert(true);
        } else {
          setAlertMessage("Unknown error occurred");
          setShowAlert(true);
        }
        setSubmitDisabled(true);
        setTimeout(() => {
          setSubmitDisabled(false);
          setShowAlert(false);
        }, 2000);
      });
  };

  const handleSaveChanges = async () => {
    try {
      if (
        !title ||
        !firstName.trim() ||
        !lastName.trim() ||
        !fatherName.trim() ||
        !age ||
        !gender ||
        !aadhaarNumber ||
        !street1.trim() ||
        !street2.trim() ||
        !cityVillage.trim() ||
        !district.trim() ||
        !state.trim() ||
        !country.trim() ||
        !mobileNumber.trim()
      ) {
        setShowRedBorders(true);

        setTimeout(() => {
          setShowRedBorders(false);
        }, 2000);

        return;
      }

      setSubmitDisabled(true);

      if (!uhidInput && !mobileNumberInput) {
        setShowRedBorders1(true);

        setTimeout(() => {
          // setShowAlert(false);
          // setAlertMessage('');
          setShowRedBorders1(false);
          setSubmitDisabled(false);
        }, 2000);
        return;
      }

      if (!/^[6-9]\d{9}$/.test(mobileNumber)) {
        setShowAlert(true);
        setAlertMessage("Please enter a valid mobileNumber");

        setTimeout(() => {
          setShowAlert(false);
          setSubmitDisabled(false);
        }, 2000);

        return;
      }

      let isDataChanged =
        title !== patientData.Title ||
        firstName !== patientData.first_Name ||
        lastName !== patientData.last_Name ||
        fatherName !== patientData.father_name ||
        dob !==
          (patientData.date_of_birth
            ? format(new Date(patientData.date_of_birth), "yyyy-MM-dd")
            : null) ||
        age !== patientData.age ||
        ageUnit !== patientData.age_Unit ||
        gender !== patientData.gender ||
        mobileNumber !== patientData.Mobile_number ||
        street1 !== patientData.street1 ||
        street2 !== patientData.street2 ||
        cityVillage !== patientData.city_village ||
        district !== patientData.district ||
        state !== patientData.state ||
        country !== patientData.country ||
        registrationDate !==
          (patientData.registration_date
            ? format(new Date(patientData.registration_date), "yyyy-MM-dd")
            : "");

      // Check if a patient is selected from the table
      if (selectedPatient && selectedPatient.uhid !== patientData.uhid) {
        isDataChanged =
          title !== selectedPatient.Title ||
          firstName !== selectedPatient.first_Name ||
          lastName !== selectedPatient.last_Name ||
          fatherName !== selectedPatient.father_name ||
          dob !==
            (selectedPatient.date_of_birth
              ? format(new Date(selectedPatient.date_of_birth), "yyyy-MM-dd")
              : null) ||
          age !== selectedPatient.age ||
          ageUnit !== selectedPatient.age_Unit ||
          gender !== selectedPatient.gender ||
          mobileNumber !== selectedPatient.Mobile_number ||
          street1 !== selectedPatient.street1 ||
          street2 !== selectedPatient.street2 ||
          cityVillage !== selectedPatient.city_village ||
          district !== selectedPatient.district ||
          state !== selectedPatient.state ||
          country !== selectedPatient.country ||
          registrationDate !==
            (selectedPatient.registration_date
              ? format(
                  new Date(selectedPatient.registration_date),
                  "yyyy-MM-dd"
                )
              : "");
      }

      const identifier = selectedPatient.uhid || patientData.uhid;
      console.log(identifier);

      const updatedData = {
        Title: title || (selectedPatient && selectedPatient.Title) || "",
        first_Name:
          firstName || (selectedPatient && selectedPatient.first_Name) || "",
        last_Name:
          lastName || (selectedPatient && selectedPatient.last_Name) || "",
        father_name:
          fatherName || (selectedPatient && selectedPatient.father_name) || "",
        date_of_birth:
          dob || (selectedPatient && selectedPatient.date_of_birth) || null,
        age: age || (selectedPatient && selectedPatient.age) || "",
        age_Unit:
          ageUnit || (selectedPatient && selectedPatient.age_Unit) || "",
        gender: gender || (selectedPatient && selectedPatient.gender) || "",
        Mobile_number:
          mobileNumber ||
          (selectedPatient && selectedPatient.Mobile_number) ||
          "",
        street1: street1 || (selectedPatient && selectedPatient.street1) || "",
        street2: street2 || (selectedPatient && selectedPatient.street2) || "",
        city_village:
          cityVillage ||
          (selectedPatient && selectedPatient.city_village) ||
          "",
        district:
          district || (selectedPatient && selectedPatient.district) || "",
        state: state || (selectedPatient && selectedPatient.state) || "",
        country: country || (selectedPatient && selectedPatient.country) || "",
      };

      console.log(updatedData);

      if (isDataChanged) {
        const response = await fetch(
          `${config.apiUrl}/registration/patient/${identifier}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedData),
          }
        );

        if (!response.ok) {
          const errorMessage = await response.text();
          console.error("Failed to update patient data:", errorMessage);
          throw new Error("Failed to update patient data");
        }

        setUpdating(true);

        setTimeout(() => {
          setShowUpdateSuccessMessage(true);
        }, 2000);

        setTimeout(() => {
          resetFormFields();
          setSubmitDisabled(false);
          setShowUpdateSuccessMessage(false);
          setShowSubmit(true);
          setUpdating(false);
        }, 4000);
        setShowInputs(!showInputs);
      } else {
        console.log("Values are equal. Update not allowed.");
        setShowAlert(true);
        setAlertMessage("No changes detected. Update not allowed.");
        setTimeout(() => {
          setShowAlert(false);
          setSubmitDisabled(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error saving changes:", error.message);
      setUpdating(false);

      setTimeout(() => {
        setSubmitDisabled(false);
      }, 2000);
    }
  };

  const handleSearchClick = async () => {
    try {
      const searchQuery = uhidInput || mobileNumberInput;

      if (!searchQuery) {
        // setShowAlert(true);
        // setAlertMessage('Please enter UHID or Mobile Number.');
        setShowRedBorders1(true);

        setTimeout(() => {
          // setShowAlert(false);
          // setAlertMessage('');
          setShowRedBorders1(false);
        }, 2000);

        return;
      }

      const response = await axios.get(
        `${config.apiUrl}/registration/patient/search`,
        {
          params: {
            uhidOrPhoneNumber: searchQuery,
          },
        }
      );

      const data = response.data;
      setEditUpdate(data);
      setPatientData(data);
      if (Array.isArray(data) && data.length > 1) {
        // Multiple patients found for the same mobile number

        // Set the list of patients with the same mobile number
        setMultiplePatients(data);
        setTablehide(false); // Show the table
        onPrintButtonClick()
        console.log("Multiple Patients:", data);
        return;
      } else if (data && data.uhid) {
        setIsAllAre(true);
        setSelectedPatient(data);
        const patientData = data;
        setUhidInput(patientData.uhid || "");
        setMobileNumberInput(patientData.Mobile_number || "");
        setTitle(patientData.Title || "");
        setFirstName(patientData.first_Name || "");
        setLastName(patientData.last_Name || "");
        setFatherName(patientData.father_name || "");

        const dateOfBirthString = patientData.date_of_birth;
        setDob(
          dateOfBirthString
            ? format(new Date(dateOfBirthString), "yyyy-MM-dd")
            : null
        );

        setAge(patientData.age || "");
        setAgeUnit(patientData.age_Unit || "");
        setGender(patientData.gender || "");
        setMobileNumber(patientData.Mobile_number || "");
        setAadhaarNumber(patientData.aadhar_number || "");
        setStreet1(patientData.street1 || "");
        setStreet2(patientData.street2 || "");
        setCityVillage(patientData.city_village || "");
        setDistrict(patientData.district || "");
        setState(patientData.state || "");
        setCountry(patientData.country || "");
        const registrationDateString = patientData.registration_date;
        setRegistrationDate(
          registrationDateString
            ? format(new Date(registrationDateString), "yyyy-MM-dd")
            : ""
        );

        setShowSubmit(false);
        setTablehide(true);
        onPrintButtonClick();
        setTimeout(() => {
          setShowAlert(false);
          setAlertMessage("");
        }, 2000);
      }
    } catch (error) {
      console.error("No patient data:", error);
      setShowAlert(true);
      setAlertMessage("Patient Data not Available.");

      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 2000);
    }
  };

  const handleMobileNumberChange = (event) => {
    const cleanedValue = event.target.value.replace(/\D/g, "");
    setMobileNumber(cleanedValue);
  };

  const handleAadhaarNumberChange = (event) => {
    const cleanedValue = event.target.value.replace(/\D/g, "");
    const truncatedValue = cleanedValue.substring(0, 12);
    setAadhaarNumber(truncatedValue);
  };

  const handleBButtonClick = () => {
    setUhidInput("");
    setMobileNumberInput("");
    resetFormFields();
    setShowSubmit(true);
    setSubmitDisabled(false);
  };
  const handleIconClick = () => {
    setShowInputs(!showInputs);
  };
  const handlePatientSelect = (selectedPatient) => {
    console.log("Selected Patient:", selectedPatient);
    setEditedData(selectedPatient);
    setSelectedPatient(selectedPatient);

    setSelectedPatientUHID(selectedPatient.uhid);
    setIsAllAre(true);
    setTablehide(true);
    // onToggleButtonClick();
    setUhidInput(selectedPatient.uhid || "");
    setTitle(selectedPatient.Title || "");
    setFirstName(selectedPatient.first_Name || "");
    setLastName(selectedPatient.last_Name || "");
    setFatherName(selectedPatient.father_name || "");

    const dateOfBirthString = selectedPatient.date_of_birth;
    setDob(
      dateOfBirthString
        ? format(new Date(dateOfBirthString), "yyyy-MM-dd")
        : null
    );

    setAge(selectedPatient.age || "");
    setAgeUnit(selectedPatient.age_Unit || "");
    setGender(selectedPatient.gender || "");
    setMobileNumber(selectedPatient.Mobile_number || "");
    setAadhaarNumber(selectedPatient.aadhar_number || "");
    setStreet1(selectedPatient.street1 || "");
    setStreet2(selectedPatient.street2 || "");
    setCityVillage(selectedPatient.city_village || "");
    setDistrict(selectedPatient.district || "");
    setState(selectedPatient.state || "");
    setCountry(selectedPatient.country || "");

    setShowSubmit(false);
    onToggleButtonClick();
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, 2000);
  };
  const handleSearchInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setFilteredPatients([]);
  };
  const handleCloseHistory=()=>{
    setShowHistory(false);
    onToggleButtonClick();
  }

  const handleClosetable=()=>{
    setTablehide(true)
    onToggleButtonClick();
  }

  return (
    <>
      {showHistory && (
        <div
          className={`prescription fixed inset-0  ${
            showHistory
              ? "backdrop-filter backdrop-blur-sm backdrop-blur-lg"
              : ""
          } transition-all duration-300 ${
            showHistory ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <style>
            {`
    @media only screen and (min-width: 1024px)  {
      .prescription{
        margin-right:320px
      }
    }`}
          </style>
          <div className="flex items-center justify-center h-screen">
            <div
              className="bg-white p-4 rounded shadow-lg transition-transform duration-300 transform scale-100 border-2 border-blue-500 max-h-full "
              style={{
                top: "4%",
                height: "100%",
                width: "95%",
              }}
            >
              <div className="p-6 text-black">
                <div className="flex justify-between items-center">
                  <h2 className="text-3xl font-bold text-blue-500 ">
                    Patient History
                  </h2>
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={handleCloseHistory}
                    className="text-red-500 cursor-pointer"
                    size="lg"
                  />
                </div>

                <div className="mb-2 mt-4">
                  <div className="flex flex-col md:flex-row justify-between items-center">
                    <div className=" flex flex-row md:flex-row">
                      <div className="">
                        <input
                          type="text"
                          placeholder="Enter Name or Mobile Number"
                          value={searchTerm}
                          onChange={handleSearchInputChange}
                          className="px-2 py-1 border placeholder-gray-500 rounded text-black w-full text-sm"
                        />
                      </div>

                      {searchTerm && (
                        <button
                          className="px-2 py-1 text-red-500 text-sm"
                          onClick={clearSearch}
                        >
                          &#x2716;
                        </button>
                      )}
                    </div>

                    <div className="flex items-center">
                      <span className="bold-placeholder me-2 text-gray-700 text-sm">
                        From: <DatePicker onChange={handleFromDateChange} />
                      </span>
                      <span className="bold-placeholder text-gray-700 text-sm">
                        To: <DatePicker onChange={handleToDateChange} />
                      </span>
                    </div>
                  </div>
                </div>

                <div className=" ">
                  {/* <h2 className="text-lg text-gray-700 font-bold" style={{ marginLeft: isMobile ? "170px" : "0px" }}>Patient History</h2> */}
                  <div className="overflow-auto" style={{ maxHeight: "480px" }}>
                    <table className="table-auto w-full mt-4">
                      <thead className="bg-blue-900 text-white">
                        <tr>
                          <th className="px-2 py-1 md:px-4 md:py-2">UHID</th>
                          <th className="px-2 py-1 md:px-4 md:py-2">Name</th>
                          {!isMobile && (
                            <>
                              <th className="px-2 py-1 md:px-4 md:py-2">
                                Father Name
                              </th>
                              <th className="px-2 py-1 md:px-4 md:py-2">
                                Aadhar Number
                              </th>
                            </>
                          )}
                          <th className="px-2 py-1 md:px-4 md:py-2">Age</th>
                          <th className="px-2 py-1 md:px-4 md:py-2">Gender</th>
                          <th className="px-2 py-1 md:px-4 md:py-2">
                            Mobile Number
                          </th>
                          <th className="px-2 py-1 md:px-4 md:py-2">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredPatients.length > 0 ? (
                          filteredPatients.map((patientData, index) => (
                            <tr
                              key={patientData.uhid}
                              className="text-black text-sm"
                            >
                              <td className="border px-2 py-2 md:px-4 md:py-2">
                                {patientData.uhid}
                              </td>
                              <td className="border px-2 py-2 md:px-4 md:py-2">{`${patientData.Title} ${patientData.first_Name} ${patientData.last_Name}`}</td>
                              {!isMobile && (
                                <>
                                  <td className="border px-2 py-2 md:px-4 md:py-2">
                                    {patientData.father_name}
                                  </td>
                                  <td className="border px-2 py-2 md:px-4 md:py-2">
                                    {patientData.aadhar_number}
                                  </td>
                                </>
                              )}
                              <td className="border px-2 py-2 md:px-4 md:py-2">
                                {patientData.age}
                              </td>
                              <td className="border px-2 py-2 md:px-4 md:py-2">
                                {patientData.gender}
                              </td>
                              <td className="border px-2 py-2 md:px-4 md:py-2">
                                {patientData.Mobile_number}
                              </td>
                              <td className="border px-2 py-2 md:px-4 md:py-2">
                                {registrationDate}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={isMobile ? "5" : "7"}
                              className="text-center text-sm"
                            >
                              {searchTerm
                                ? "No matching results found"
                                : "No patient data available"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!showHistory && (
        <div class="container mt-5 text-black mx-auto px-4 sm:px-6 lg:px-8">
          <style>
            {`

            .idcard{
              height:35vh;
            }
       @media screen and (max-width: 640px) {
  .demo {
    margin-top: 600px;
  }
}

@media(max-width: 600px){

  .custom-table-style td,
  .custom-table-style th {
    font-size:8px;
  }

   .idcard{
    width:80vw;
    height:24vh;
   }
  }

   .custom-table-style td,
            .custom-table-style th {
              border: none !important;
              font-size:12px;
            }

        `}
          </style>

          <div
            className={`flex md:items-center float-right m p-2 mr-6" mt-${
              isMobile ? 300 : 0
            } ${isMobile ? "float-left" : "float-right"}`}
          >
            <label className="mb-1 mr-2 label-width text-gray-700 text-lg">
              {" "}
              Date:
            </label>
            <input
              type="text"
              className="border border-gray-300 text-gray-700 rounded-md p-2 w-36"
              value={registrationDate}
              readOnly
            />
          </div>

          <div className="h-full demo pl-6 pr-8  pt-16 pb-20"
        >
            <div>
            <div
  style={{
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    marginTop: isMobile && showInputs ? "220px" : "2px",
  }}
>
  <div className="mb-2" style={{  display: "flex", alignItems: isMobile ? "flex-start" : "center",}}>
  <h2 className="mr-2 text-xl font-bold text-gray-700">
    Patient Details
  </h2>
  <FontAwesomeIcon
    icon={faSearch}
    onClick={handleIconClick}
    className=" cursor-pointer text-white bg-sky-700 px-2 py-2 rounded-full "
  />
  </div>
 
  <div
    onClick={handleHistoryButtonClick}
    className={`bg-blue-900 hover:bg-green-600 text-white py-2 px-2 rounded cursor-pointer ${
      isMobile ? "mb-2 w-50" : "ml-auto mt-2 mb-2"
    }`}
    title="Search Patient History"
  >
    <FontAwesomeIcon icon={faHistory} className="mr-1" />
    History
  </div>
</div>


              <div>
                {showInputs && (
                  <div className="mb-3">
                    <div
                      style={{ display: "inline-block", marginRight: "20px" }}
                    >
                      <label
                        className="inline text-gray-700 "
                        style={{ marginRight: "8px" }}
                      >
                        <strong> UHID:</strong>
                      </label>

                      <input
                        type="text"
                        id="uhidInput"
                        list="uhidOptions"
                        value={uhidInput.toUpperCase()}
                        onChange={(e) => setUhidInput(e.target.value)}
                        style={{
                          backgroundColor: "white",
                          border:
                            showRedBorders1 && !uhidInput
                              ? "1px solid red"
                              : "1px solid #ccc",
                          padding: "0.5rem",
                          borderRadius: "0.25rem",
                          width: "190px",
                          height: "40px",
                          color: "black",
                          marginLeft: isMobile ? "0px" : "60px",
                        }}
                      />
                      <datalist id="uhidOptions">
            {uhidOptions.map((option, index) => (
              <option key={index} value={option} />
            ))}
          </datalist>
                    </div>

                    <div style={{ display: "inline-block" }}>
                      <label
                        className="inline text-gray-700"
                        style={{ marginRight: "8px" }}
                      >
                        <b>Mobile No:</b>
                      </label>
                      <input
                        type="tel"
                        list="mobileNumberOptions"
                        pattern="[0-9]{10}"
                        maxLength="10"
                        value={mobileNumberInput}
                        onChange={(e) => {
                          const inputValue = e.target.value.replace(/\D/g, "");
                          setMobileNumberInput(inputValue);
                        }}
                        style={{
                          backgroundColor: "white",
                          border:
                            showRedBorders1 && !mobileNumberInput
                              ? "1px solid red"
                              : "1px solid #ccc",
                          padding: "0.5rem",
                          borderRadius: "0.25rem",
                          width: "190px",
                          height: "40px",
                          color: "black",

                          marginLeft: isMobile ? "0px" : "16px",
                        }}
                      />
                       <datalist id="mobileNumberOptions">
            {mobileNumberOptions.map((option, index) => (
              <option key={index} value={option} />
            ))}
          </datalist>
                    </div>

                    <button
                      onClick={handleSearchClick}
                      className="bg-blue-900  hover:bg-green-700   text-white px-4 py-2 ml-2 rounded-md "
                      
                    >
                      Search
                    </button>
                    <button
                      onClick={handleBButtonClick}
                      className="bg-blue-900  hover:bg-red-700   text-white px-4 py-2 ml-2 rounded-md "
                      style={{
                        marginTop: isMobile ? "10px" : "0",
                      }}
                    >
                        Clear
                    </button>
                   
                  </div>
                )}
              </div>

              <div className="flex flex-col mb-3 md:flex-row md:items-center md:justify-between md:space-x-4 mt-2">
                <div className="flex flex-col items-start md:flex-row md:items-center md:mt-0">
                  <label
                    className=" mb-1 text-gray-700 whitespace-nowrap label-width"
                    style={{ marginRight: "74px" }}
                  >
                    <strong>Title</strong>{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <select
                    value={isFirstTimeRegistration ? title : editedData.title}
                    onChange={handleTitleChange}
                    style={{
                      backgroundColor: "white",
                      border:
                        showRedBorders && !title
                          ? "1px solid red"
                          : "1px solid #ccc",
                      padding: "0.5rem",
                      borderRadius: "0.25rem",
                      color: "black",
                      width: "19.0px",
                      //  width: "100%", // Full width on small screens
                      // maxWidth: "200px",
                      minWidth: "190px", // Maximum width on larger screens
                      height: "40px",
                      "@media screen and (min-width: 768px) and (max-width: 1024px)":
                        {
                          width: "100%", // Allow responsiveness
                          maxWidth: "200px", // Limit maximum width
                        },
                    }}
                  >
                    <option value=""> Select Title </option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Baby">Baby</option>
                    <option value="Baby boy of">Baby boy of</option>
                    <option value="Baby girl of">Baby girl of</option>
                  </select>
                </div>

                <div className="flex flex-col items-start md:flex-row md:items-center md:mt-0">
                  <label className="mb-1 mr-4 whitespace-nowrap text-gray-700 label-width">
                    <strong>First Name</strong>{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={
                      isFirstTimeRegistration ? firstName : editedData.firstName
                    }
                    onChange={handleFirstNameChange}
                    style={{
                      backgroundColor: "white",
                      border:
                        showRedBorders && !firstName
                          ? "1px solid red"
                          : "1px solid #ccc",
                      padding: "0.5rem",
                      borderRadius: "0.25rem",
                      width: "100%", // Full width on small screens
                      maxWidth: "190px", // Maximum width on larger screens
                      height: "40px",
                      color: "black",

                      marginRight: "3px",
                    }}
                  />
                </div>

                <div className="flex flex-col items-start md:flex-row md:items-center md:mt-0">
                  <label className="mb-1 mr-3 whitespace-nowrap text-gray-700 label-width">
                    <strong>Last Name</strong>{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={
                      isFirstTimeRegistration ? lastName : editedData.lastName
                    }
                    onChange={handleLastNameChange}
                    style={{
                      backgroundColor: "white",
                      border:
                        showRedBorders && !lastName
                          ? "1px solid red"
                          : "1px solid #ccc",
                      padding: "0.5rem",
                      borderRadius: "0.25rem",
                      width: "100%", // Full width on small screens
                      maxWidth: "190px", // Maximum width on larger screens
                      height: "40px",
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row mb-3 md:items-center md:justify-between md:space-x-4 mt-2">
                <div className="flex flex-col items-start md:flex-row md:items-center md:mt-0">
                  <label className="mb-1 mr-2 whitespace-nowrap text-gray-700 label-width">
                    <strong>Father Name </strong>
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={
                      isFirstTimeRegistration
                        ? fatherName
                        : editedData.fatherName
                    }
                    style={{
                      backgroundColor: "white",
                      border:
                        showRedBorders && !fatherName
                          ? "1px solid red"
                          : "1px solid #ccc",
                      padding: "0.4rem",
                      borderRadius: "0.25rem",
                      width: "100%", // Full width on small screens
                      maxWidth: "190px", // Maximum width on larger screens
                      height: "40px",
                      color: "black",

                      marginLeft: "2px",
                    }}
                    onChange={handleFatherNameChange}
                  />
                </div>

                <div className="flex flex-col items-start md:flex-row md:items-center md:mt-0">
                  <label className="mb-1 mr-4 whitespace-nowrap text-gray-700 label-width">
                    <strong>Date of Birth</strong>
                  </label>
                  <input
                    type="date"
                    className="border border-gray-300 p-2 rounded-md text-black fixed-size-input"
                    value={isFirstTimeRegistration ? dob : editedData.dob}
                    onChange={handleDobChange}
                    style={{
                      width: "190px",
                      maxWidth: "190px",
                      height: "40px",
                    }}
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>

                <div className="flex flex-col items-start  md:flex-row md:items-center md:mt-0">
                  <label className="mb-1  mr-16 text-gray-700 whitespace-nowrap ">
                    <strong>Age</strong> <span className="text-red-500">*</span>
                  </label>
                  <div className="flex items-center space-x-1">
                    <input
                      type="number"
                      style={{
                        backgroundColor: "white",
                        border:
                          showRedBorders && !age && !dob
                            ? "1px solid red"
                            : "1px solid #ccc",
                        padding: "0.5rem",
                        borderRadius: "0.25rem",
                        width: "100px",
                        maxWidth: "190px",
                        height: "40px",
                      }}
                      value={isFirstTimeRegistration ? age : editedData.age}
                      onChange={handleAgeChange}
                      readOnly={!!dob}
                    />
                    <select
                      className="border border-gray-300 rounded-md  p-2 text-sm "
                      value={
                        isFirstTimeRegistration ? ageUnit : editedData.ageUnit
                      }
                      onChange={handleAgeUnitChange}
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        width: "100%", // Full width on small screens
                        maxWidth: "190px", // Maximum width on larger screens
                        height: "40px",
                      }}
                      readOnly={!!dob}
                    >
                      <option value="days">Days</option>
                      <option value="months">Months</option>
                      <option value="years">Years</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:items-center md:justify-between md:space-x-4 mt-2">
                <div className="flex flex-col items-start md:flex-row md:items-center md:mt-0">
                  <label
                    className="mb-1 text-gray-700 whitespace-nowrap label-width"
                    style={{ marginRight: "50px" }}
                  >
                    <strong>Gender</strong>{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <select
                  
                    style={{
                      backgroundColor: "white",
                      border:
                        showRedBorders && !gender
                          ? "1px solid red"
                          : "1px solid #ccc",
                      padding: "0.5rem",
                      borderRadius: "0.25rem",
                      width: "190px",
                      maxWidth: "190px", 
                      height: "40px",
                    }}
                    value={isFirstTimeRegistration ? gender : editedData.gender}
                    onChange={handleGenderChange}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="transgender">TransGender</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div className="flex flex-col items-start md:flex-row md:items-center md:mt-0">
                  <label className="mb-1 mr-4 text-gray-700 whitespace-nowrap label-width">
                    <strong>Mobile No</strong>{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="tel"
                    pattern="[0-9]{10}"
                    maxLength="10"
                    style={{
                      backgroundColor: "white",
                      border:
                        showRedBorders && !mobileNumber
                          ? "1px solid red"
                          : "1px solid #ccc",
                      padding: "0.5rem",
                      borderRadius: "0.25rem",
                      width: "100%", 
                      maxWidth: "190px", 
                      height: "40px",
                      marginLeft: isMobile ? "0" : "9px",
                    }}
                    value={
                      isFirstTimeRegistration
                        ? mobileNumber
                        : editedData.mobileNumber
                    }
                    onChange={handleMobileNumberChange}
                  />
                </div>

                <div className="flex flex-col items-start md:flex-row md:items-center md:mt-0">
                  <label className="mb-1 mr-3 text-gray-700 label-width">
                    <strong>Aadhaar No</strong>{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  {isallare ? (
                    <input
                      type="tel"
                      pattern="[0-9]{10}"
                      maxLength="10"
                      style={{
                        backgroundColor: "white",
                        border:
                          showRedBorders && !aadhaarNumber
                            ? "1px solid red"
                            : "1px solid #ccc",
                        padding: "0.5rem",
                        borderRadius: "0.25rem",
                        width: "100%", 
                        maxWidth: "190px", 
                        height: "40px",
                        color: "black",

                        marginLeft: isMobile ? "0" : "9px",
                      }}
                      value={
                        isFirstTimeRegistration
                          ? aadhaarNumber
                          : editedData.aadhaarNumber
                      }
                      onChange={handleAadhaarNumberChange}
                      readOnly
                    />
                  ) : (
                    <input
                      type="text"
                      pattern="[0-9]{12}"
                      maxLength="12"
                      style={{
                        backgroundColor: "white",
                        border:
                          showRedBorders && !aadhaarNumber
                            ? "1px solid red"
                            : "1px solid #ccc",
                        padding: "0.5rem",
                        borderRadius: "0.25rem",
                        width: "100%", // Full width on small screens
                        maxWidth: "190px", // Maximum width on larger screens
                        height: "40px",
                        color: "black",

                        marginLeft: isMobile ? "0" : "9px",
                      }}
                      value={
                        isFirstTimeRegistration
                          ? aadhaarNumber
                          : editedData.aadhaarNumber
                      }
                      onChange={handleAadhaarNumberChange}
                    />
                  )}
                </div>
              </div>
            </div>

            <div>
              <h2 className="text-xl  mt-2 text-gray-700 mb-4">
                <strong>Home Address</strong>
              </h2>
              <div className="flex flex-col text-gray-700 mb-4 md:flex-row md:items-center md:justify-between md:space-x-4 mt-2">
                <div className="flex flex-col items-start md:flex-row md:items-center md:mt-0">
                  <label className="mb-1 mr-2 whitespace-nowrap label-width">
                    <strong>Country</strong>{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 p-2  rounded-md "
                    style={{
                      width: "100%", // Full width on small screens
                      maxWidth: "190px", // Maximum width on larger screens
                      height: "40px",
                      color: "black",

                      marginLeft: isMobile ? "0" : "30px",
                    }}
                    value={country}
                    onChange={handleCountryChange}
                    readOnly
                  />
                </div>

                <div className="flex flex-col items-start md:flex-row md:items-center md:mt-0">
                  <label className="mb-1 mr-2 text-gray-700 whitespace-nowrap label-width">
                    <strong>State</strong>{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 p-2 rounded-md "
                    style={{
                      width: "100%", // Full width on small screens
                      maxWidth: "190px", // Maximum width on larger screens
                      height: "40px",
                      color: "black",

                      // marginLeft:"10px"
                    }}
                    value={state}
                    onChange={handleStateChange}
                    readOnly
                  />
                </div>

                <div className="flex flex-col items-start md:flex-row md:items-center md:mt-0">
                  <label className="mb-1 mr-2  text-gray-700 whitespace-nowrap label-width">
                    <strong>District</strong>{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <select
                    // className={`border ${
                    //   showRedBorders && !district
                    //     ? "border-red-500"
                    //     : "border-gray-300"
                    // } p-2 rounded-md fixed-size-input`}
                    style={{
                      backgroundColor: "white",
                      border:
                        showRedBorders && !district
                          ? "1px solid red"
                          : "1px solid #ccc",
                      padding: "0.5rem",
                      borderRadius: "0.25rem",
                      width: "100%", // Full width on small screens
                      maxWidth: "190px", // Maximum width on larger screens
                      height: "40px",
                    }}
                    // style={{ backgroundColor: "white", width:'200px', height:'40px' }}
                    value={district}
                    onChange={handleDistrictChange}
                  >
                    <option value="">Select District</option>
                    {districtsOptions.map((districtOption) => (
                      <option key={districtOption} value={districtOption}>
                        {districtOption}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:items-center md:justify-between md:space-x-4 mt-2">
                <div className="flex flex-col items-start md:flex-row md:items-center md:mt-0">
                  <label className="mb-1 mr-2 text-gray-700 whitespace-nowrap label-width">
                    <strong>Street1</strong>{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Street 1"
                    // className={`border ${
                    //   showRedBorders && !street1
                    //     ? "border-red-500"
                    //     : "border-gray-300"
                    // } p-2 rounded-md  fixed-size-input`}
                    style={{
                      backgroundColor: "white",
                      border:
                        showRedBorders && !street1
                          ? "1px solid red"
                          : "1px solid #ccc",
                      padding: "0.5rem",
                      borderRadius: "0.25rem",
                      width: "100%", // Full width on small screens
                      maxWidth: "190px", // Maximum width on larger screens
                      height: "40px",
                      color: "black",

                      marginLeft: isMobile ? "0" : "38px",
                    }}
                    value={
                      isFirstTimeRegistration ? street1 : editedData.street1
                    }
                    onChange={handleStreet1Change}
                  />
                </div>

                <div className="flex flex-col items-start md:flex-row md:items-center md:mt-0">
                  <label className="mb-1 mr-2 text-gray-700 whitespace-nowrap label-width">
                    <strong>Street2</strong>{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Street 2"
                    // className={`border ${
                    //   showRedBorders && !street2
                    //     ? "border-red-500"
                    //     : "border-gray-300"
                    // } p-2 rounded-md  fixed-size-input`}
                    style={{
                      backgroundColor: "white",
                      border:
                        showRedBorders && !street2
                          ? "1px solid red"
                          : "1px solid #ccc",
                      padding: "0.5rem",
                      borderRadius: "0.25rem",
                      width: "100%", // Full width on small screens
                      maxWidth: "190px", // Maximum width on larger screens
                      height: "40px",
                      color: "black",

                      marginLeft: isMobile ? "0" : "31px",
                    }}
                    value={
                      isFirstTimeRegistration ? street2 : editedData.street2
                    }
                    onChange={handleStreet2Change}
                  />
                </div>

                <div className="flex flex-col items-start md:flex-row md:items-center md:mt-0">
                  <label className="mb-1 mr-1 text-gray-700 whitespace-nowrap label-width">
                    <strong>Town/Village</strong>{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    style={{
                      backgroundColor: "white",
                      border:
                        showRedBorders && !cityVillage
                          ? "1px solid red"
                          : "1px solid #ccc",
                      padding: "0.5rem",
                      borderRadius: "0.25rem",
                      width: "100%", // Full width on small screens
                      maxWidth: "190px", // Maximum width on larger screens
                      height: "40px",
                    }}
                    value={
                      isFirstTimeRegistration
                        ? cityVillage
                        : editedData.cityVillage
                    }
                    placeholder="Enter Town/Village"
                    onChange={handleCityVillageChange}
                  />
                </div>
              </div>
            </div>

            <div className="flex float-right  ">
              {showSubmit ? (
                <>
                  <button
                    onClick={handleBButtonClick}
                    className={`bg-blue-900 text-gray-700 hover:bg-red-700  text-white font-bold mt-4 px-4 py-2 rounded mr-4 ${
                      isSubmitDisabled ? "cursor-not-allowed opacity-50" : ""
                    }`}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSubmit}
                    className={`bg-blue-900 text-gray-700  hover:bg-green-700  text-white font-bold mt-4 px-4 rounded mr-4 rounded-md ${
                      isSubmitDisabled ? "cursor-not-allowed opacity-50" : ""
                    }`}
                    disabled={isSubmitDisabled}
                  >
                    Submit
                  </button>
                </>
              ) : (
                <button
                  className={`bg-green-700  text-white p-2 mt-4 rounded-md ml-8 ${
                    isSubmitDisabled ? "cursor-not-allowed opacity-50" : ""
                  }`}
                  onClick={handleSaveChanges}
                  disabled={isSubmitDisabled}
                >
                  Update
                </button>
              )}
              {isSubmitting && <LoadingSpinner />}
              {isUpdating && <LoadingSpinner />}
              {showSuccessMessage && (
                <div
                  className="fixed left-1/3 transform -translate-x-1/2 -translate-y-1/2  text-white p-2 rounded-md"
                  style={{
                    top: "80px",
                    background:
                      "linear-gradient(to bottom right, #87CEEB, #008080)",
                  }}
                >
                  Patient data submitted successfully
                </div>
              )}
              {showUpdateSuccessMessage && (
                <div
                  className="fixed left-1/3 transform -translate-x-1/2 -translate-y-1/2  text-white p-2 rounded-md"
                  style={{
                    top: "80px",
                    background:
                      "linear-gradient(to bottom right, #87CEEB, #008080)",
                  }}
                >
                  Patient data updated successfully
                </div>
              )}
            </div>

            {showAlert && (
              <div
                className="fixed left-1/3 transform -translate-x-1/2 -translate-y-1/2  text-white p-2 rounded-md"
                style={{ top: "80px", background: "red" }}
              >
                {alertMessage}
              </div>
            )}
          </div>

          {showIdCard && (
           <div className="overlay " >
           <div className={`fixed inset-0  ${showIdCard ? "backdrop-filter backdrop-blur-sm" : ""} transition-all duration-300 ${showIdCard ? "opacity-100" : "opacity-0 pointer-events-none"}`}>
             <div className="idcard container-fluid position-fixed top-0 start-0 end-0 bottom-0 bg-white border border-2 border-primary rounded shadow-lg  " style={{ width: "90%", maxWidth: "380px", margin: "auto", overflowY: "auto"}}>
               <button className="text-danger cursor-pointer bg-transparent border-0 "style={{float:'right'}}  onClick={() => {
        handleClose();
      }}>&#10006;</button>
               <div   ref={componentRef}>
                 <div className="container-fluid">
                   <div classname='row'>
                     <div className="col-12">
               <div className="text-center  d-flex" style={{display:'flex', alignItems:'center'}}>
                 <img  src={logo} alt="Hospital Logo" style={{ width:'50px',height:'50px',  borderRadius: "50%" }} />
                 <h4 style={{ color: 'rgb(40, 204, 205)',marginLeft:'3px', fontSize:'24px'}}>Linga Dental Clinic</h4>
               </div>
               </div>
               </div>
               </div>
              
               <div className="container-fluid ml-2">
                 <div className="row">
                   <div className="col-12">
                     <div className="table-responsive">
                     <table className="table custom-table-style" > 
          <tbody >
            <tr>
              <td className="text-gray-700  text-start p-1"><b>UHID</b></td>
              <td className="p-1"><b>: {idCardData.uhid}</b></td>
            </tr>
            <tr >
              <td className="text-gray-700 text-start p-1"><b>Name</b></td>
              <td className="p-1"><b>: {idCardData.name}</b></td>
            </tr>
            <tr>
              <td className="text-gray-700  text-start p-1"><b>Age</b></td>
              <td className="p-1"><b>: {idCardData.age}/{idCardData.gender}</b></td>
            </tr>
            <tr>
              <td className="text-gray-700  text-start p-1"><b>Mobile No</b></td>
              <td className="p-1"><b>: {idCardData.mobileNumber}</b></td>
            </tr>
          </tbody>
        </table>
                     </div>
                   </div>
                  
                 </div>
               </div>
               </div>
               <div className="text-center"> 
    <button style={{marginBottom:'2px'}} className="bg-blue-900 hover:bg-blue-500 text-white font-bold p-1 fs-[10px]" onClick={handlePrint}>Print</button>
  </div>
             </div>
           </div>
         </div>
          )}

          

          {!tablehide && multiplePatients && multiplePatients.length > 0 && (
            <>
            <style>
              {`@media only screen and (min-width: 1024px)  {
    .testt{
      margin-right:320px
    }
  }`}
            </style>
            <div
            className={`testt fixed inset-0  ${
              !tablehide && multiplePatients && multiplePatients.length > 0 ? "backdrop-filter backdrop-blur-sm backdrop-blur-lg" : ""
            } transition-all duration-300 ${
              !tablehide && multiplePatients && multiplePatients.length > 0 ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
          >
            <div className="flex items-center justify-center h-screen">
              <div
                className="bg-white p-4 rounded shadow-lg transition-transform duration-300 transform scale-100 border-2 border-blue-500 max-h-full "
                style={{
                  top: "4%",
                  height: "83%",
                  width: "95%",
                  // marginRight: "290px",
                }}
              >
                <div className="top-2 right-2 ">
                 
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={handleClosetable}                    className="text-red-500 cursor-pointer"
                    size="lg"
                  />
                </div>
             
                {/* <button
                  className="absolute top-2 left-2 text-red-500 text-2xl"
                  onClick={() => setTablehide(true)}
                >
                  &#x2716;
                </button> */}
                <h5 className="text-center mb-4">Please Select a Patient:</h5>
                <div className="overflow-auto" style={{ maxHeight: "400px" }}>
                <table className="w-full table-auto">
                  <thead className="text-center text-white bg-blue-900" >
                    <tr>
                      <th className="px-4 py-2">UHID</th>
                      <th className="px-4 py-2">First Name</th>
                      <th className="px-4 py-2">Last Name</th>
                      <th className="px-4 py-2">Father Name</th>
                      <th className="px-4 py-2">Age</th>
                      <th className="px-4 py-2">Aadhar Number</th>
                      <th className="px-4 py-2">Mobile Number</th>
                      <th className="px-4 py-2">Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    {multiplePatients.map((patient, index) => (
                      <tr key={index} className="text-center">
                        <td className="border px-4 py-2">{patient.uhid}</td>
                        <td className="border px-4 py-2">
                          {patient.first_Name}
                        </td>
                        <td className="border px-4 py-2">
                          {patient.last_Name}
                        </td>
                        <td className="border px-4 py-2">
                          {patient.father_name}
                        </td>
                        <td className="border px-4 py-2">{patient.age}</td>
                        <td className="border px-4 py-2">
                          {patient.aadhar_number}
                        </td>
                        <td className="border px-4 py-2">
                          {patient.Mobile_number}
                        </td>
                        <td className="border px-4 py-2">
                          <button
                            className="bg-blue-500 text-white px-2 py-1 rounded"
                            onClick={() => handlePatientSelect(patient)}
                          >
                            Select
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
               
              </div>
        </div>
        </div>
            </>
          
          )}
        </div>
      )}
    </>
  );
};

export default CheckboxApp;
