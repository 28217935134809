import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChevronRight,
  faChevronLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import config from "../config";
import { Table, Modal } from "react-bootstrap";
import PrintComponent from "./mrprint";

function PrescriptionHistory({props}) {
  const { prescriptionData: propPrescriptionData } = props||{};


  const [uhid, setUhid] = useState("");

  const [patientname, setPatientName] = useState('');
  const [patientNameOptions, setPatientNameOptions] = useState([]);
    const [prescriptionData, setPrescriptionData] = useState([]);
  const [print, setPrint] = useState([]);
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [uhidOptions, setUHIDOptions] = useState([]);
  // const [patientNameOptions, setPatientNameOptions] = useState([]);
 
  const [selectedUHIDOptions, setSelectedUHIDOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);

  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [showPrintComponent, setShowPrintComponent] = useState(false); // State to control the visibility of the PrintComponent

  const viewPrescription = (uhid) => {
    axios
      .get(`${config.apiUrl}/doctor/getprescription/${uhid}`)
      .then((response) => {
        const data = response.data.prescriptionData;
        data.sort(
          (a, b) => new Date(a.consultationDate) - new Date(b.consultationDate)
        );
        const filteredData = data.filter(
          (prescription) => prescription.uhid === uhid
        );
        setSelectedPrescription(uhid);
        setPrescriptionData(filteredData);
        console.log("setSelectedPrescription", selectedPrescription);
        console.log("setPrescriptionData", prescriptionData);
      })
      .catch((error) => {
        console.error(
          "Error fetching prescription data for selected UHID:",
          error
        );
      });
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
    setShowNotFoundMessage(false);
  };

  const closeModal = () => {
    setSelectedPrescription(null);
    setPrescriptionData([]);
    setCurrentPage(0);
  };

  const handleClose = () => {
    setShowPrintComponent(false);
  };


  useEffect(() => {
    fetchPatientNams();
  }, []);

  const fetchPatientNams = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/doctor/patientregistration/patientname`
      );
   
      setPatientNameOptions(response.data);
    } catch (error) {
      console.error("Error fetching UHIDs:", error);
    }
  };
 
  
  

  const handleUHIDSelection = (uhidOptions) => {
    const uniqueUHIDs = [...new Set(uhidOptions)];
    setSelectedUHIDOptions(uniqueUHIDs);
  };

  const handleClosePrint = () => {
    setShowPrintComponent(false);
    
  };

  const handleSelectedUHID = (selectedUHID) => {
    const [uhid] = selectedUHID.split("-");
    axios
      .get(`${config.apiUrl}/doctor/getprescription/${uhid}`)
      .then((response) => {
        const data = response.data.prescriptionData;
        setPrescriptionData(data);
      })
      .catch((error) => {
        console.error(
          "Error fetching prescription data for selected UHID:",
          error
        );
      });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 8) {
      setPrescriptionData("");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShowNotFoundMessage(false);
  };
 

  useEffect(() => {
    
    console.log("propPrescriptionData has changed:", propPrescriptionData);

  }, [propPrescriptionData]);

  


  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const itemsPerPage = 1;

  const totalPages = Math.ceil(prescriptionData.length / itemsPerPage);

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const visiblePages = 5;
    let startPage, endPage;
 
    if (totalPages <= visiblePages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const middlePage = Math.floor(visiblePages / 2);
      if (currentPage <= middlePage) {
        startPage = 1;
        endPage = visiblePages;
      } else if (currentPage + middlePage >= totalPages) {
        startPage = totalPages - visiblePages + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - middlePage;
        endPage = currentPage + middlePage;
      }
    }
 
    return Array.from({ length: (endPage - startPage + 1) }, (_, i) => startPage + i);
  };

  const getUniqueUHIDs = (data) => {
    return [...new Set(data.map((prescription) => prescription.uhid))];
  };



  
  return (
    <div className="container text-gray-700">
          <div className="row mt-4 ">
        <div className="col">
          <h2 className="text-2xl font-bold text-blue-500">Patient History</h2>
        </div>
        <div className="row mb-4 flex items-center border rounded-full p-2  h-14 relative" style={{width:'372px'}}>
       <input
  type="text"
  placeholder="Search by Patient Name (or) UHID (or) Ph No."
  className="flex-grow rounded-full outline-none"
  value={searchInput}
  list="patientNameOptions"
  onChange={handleSearchInputChange}
/>
<datalist id="patientNameOptions">
    {patientNameOptions.map((option, index) => (
      <option key={index} value={option} />
    ))}
  </datalist>

      </div>

      </div>

      <div className="row mt-1 overflow-auto" style={{ maxHeight: "450px" }}>
        <div className="col ">
          <table className=" table table-striped table-bordered">
            <colgroup>
              <col style={{ width: "10%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "30%" }} />
            </colgroup>
            <thead className="custom-table-head bg-blue-900 text-white">
              <tr>
                <th className="border p-2 text-center">S.No</th>
                <th className="border p-2 text-center">Date</th>
                <th className="border p-2 text-center">UHID</th>
                <th className="border p-2 text-center">Patient Name</th>
                <th className="border p-2 text-center">Mobile Number</th>
                <th className="border p-2 text-center">Report</th>
              </tr>
            </thead>
            <tbody>
      {getUniqueUHIDs(propPrescriptionData)
       .filter(uhid =>
        propPrescriptionData.find(prescription => prescription.uhid === uhid)
        .patientname.toLowerCase().includes(searchInput.toLowerCase()) || 
        uhid.toLowerCase().includes(searchInput.toLowerCase()) ||
        propPrescriptionData.find(prescription => prescription.uhid === uhid)
        .mobile_number.includes(searchInput)      )      
      .map((uhid, index) => {
        const firstPrescription = propPrescriptionData.find(
          (prescription) => prescription.uhid === uhid
        );

        return (
          <tr key={index}>
            <td className="border p-2 text-center">{index + 1}</td>
            <td className="text-center">
            {new Date(firstPrescription.date).toLocaleDateString("en-US")}
            </td>
            <td className="text-center">{firstPrescription.uhid}</td>
            <td className="text-center">{firstPrescription.patientname}</td>
            <td className="text-center">{firstPrescription.mobile_number}</td>
            <td className="text-center">
              <button
                onClick={() => viewPrescription(firstPrescription.uhid)}
                className="text-blue-500 underline cursor-pointer"
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/128/3596/3596029.png"
                  alt="Report Icon"
                  style={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                  }}
                />
              </button>
            </td>
          </tr>
        );
      })}

{getUniqueUHIDs(propPrescriptionData)
                .filter((uhid) =>
                  propPrescriptionData.find(
                    (prescription) => prescription.uhid === uhid
                  ).patientname.toLowerCase().includes(searchInput.toLowerCase()) ||
                  uhid.toLowerCase().includes(searchInput.toLowerCase()) ||
                  propPrescriptionData.find(
                    (prescription) => prescription.uhid === uhid
                  ).mobile_number.includes(searchInput)
                )
                .length === 0 && !loader && (
                  <tr>
                    <td colSpan="6" className="text-center text-red-500">
                      No data found for the given search input.
                    </td>
                  </tr>
                )}
    </tbody>
          </table>
        </div>
      </div>

      {selectedPrescription && (
        <div
          className={`fixed inset-0  text-black ${
            selectedPrescription
              ? "backdrop-filter bg-white"
              : ""
          } transition-all duration-300 ${
            selectedPrescription
              ? "opacity-100"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <div className="flex items-center justify-center h-screen">
            <div
              className="bg-white p-4 rounded shadow-lg transition-transform duration-300 transform scale-100 border-2 border-blue-500 max-h-full overflow-auto"
              style={{ width: "1200px" }}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                style={{
                  maxWidth: "100%",
                  marginLeft: "30px",
                  padding: "10px",
                }}
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title"></h5>
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={closeModal}
                      className="text-red-500 cursor-pointer float-right"
                      size="lg"
                    />
                  </div>
                  <div
                    style={{
                      border: "1px solid grey",
                      backgroundImage: `url('../Assets/invoice.jpg')`,
                      backgroundSize: "205mm 290mm",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      height: "290mm",
                      width: "205mm",
                    }}
                  >
                    <div style={{ padding: "50px", marginTop: "350px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="">
                          <p>
                            <b>Visit On: </b>
                            {prescriptionData[currentPage].date.split(',')[0].trim()}
                          </p>
                        </div>
                        <div>
                          <button
                            onClick={() => setShowPrintComponent(true)}
                            className="bg-blue-900 text-white p-2 rounded"
                          >
                         Invoice History
                          </button>
                        </div>
                      </div>

                      <div className="">
                        <h2
                          className="text-lg font-bold "
                          style={{ color: "teal" }}
                        >
                          Patient's Information
                        </h2>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div>
                            <p>
                              <b>UHID: </b>
                              {prescriptionData[currentPage].uhid}
                            </p>
                            <p>
                              <b>Patient Name:</b>{" "}
                              {prescriptionData[currentPage].patientname}
                            </p>
                          </div>
                          <div>
                            <div className="">
                              <p>
                                <b>Age:</b> {prescriptionData[currentPage].age}/
                                {prescriptionData[currentPage].gender}
                              </p>

                              <p>
                                <strong>Mobile Number :</strong>
                                {prescriptionData[currentPage].mobile_number}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <h2
                          className="text-lg font-bold text-blue-500 "
                          style={{ color: "teal" }}
                        >
                          Recent Health Obervation
                        </h2>
                        <table
                          style={{
                            width: "96%",
                            borderCollapse: "collapse",
                            marginLeft: "50px",
                          }}
                        >
                          <tbody>
                            <tr>
                              <th style={{}}>Current Dental Challenge</th>
                              <td
                                style={{
                                  maxWidth: "220px",
                                  wordWrap: "break-word",
                                }}
                              >{` ${prescriptionData[currentPage].medicalaffiction}`}</td>
                            </tr>
                            <tr>
                              <th style={{}}>Treatment Given</th>
                              <td
                                style={{
                                  maxWidth: "220px",
                                  wordWrap: "break-word",
                                }}
                              >
                               
                                {prescriptionData[currentPage].treatmentgiven}
                              </td>
                            </tr>

                            <tr>
                              <th style={{}}>Follow-up Date</th>
                              <td>
                                :
                                {new Date(
                                  prescriptionData[currentPage].followupdate
                                ).toLocaleDateString("en-US")}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="modal-body mt-4 mb-10">
  <div
    key={currentPage}
    className="overflow-auto"
    style={{ maxHeight: "280px" }}
  >
    <table className="table">
      <thead className="text-center bg-blue-900 text-white">
        <tr>
          <th>Name</th>
          <th>Brand Name</th>
          <th>Frequency</th>
          <th>Instruction</th>
          <th colSpan="4"> Time Schedule</th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th className="text-center">Morning</th>
          <th className="text-center">Afternoon</th>
          <th className="text-center">Evening</th>
          <th className="text-center">Night</th>
        </tr>
      </thead>
      <tbody>
        {prescriptionData[currentPage] &&
        prescriptionData[currentPage].prescription &&
        JSON.parse(prescriptionData[currentPage].prescription).some(
          (item) => item.name.trim() !== ""
        ) ? (
          JSON.parse(prescriptionData[currentPage].prescription).map(
            (item, idx) =>
              item.name.trim() !== "" && (
                <tr key={idx}>
                  <td>{item.name}</td>
                  <td>{item.brandname}</td>
                  <td>{item.frequency}</td>
                  <td>{item.instruction}</td>
                  <td className="text-center">
                    {item.morning ? "✔" : "0"}
                  </td>
                  <td className="text-center">
                    {item.afternoon ? "✔" : "0"}
                  </td>
                  <td className="text-center">
                    {item.evening ? "✔" : "0"}
                  </td>
                  <td className="text-center">{item.night ? "✔" : "0"}</td>
                </tr>
              )
          )
        ) : (
          <tr>
            <td colSpan="8" className="text-center">
              No prescription was added.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>


                      <div className="modal-footer justify-content-between">
                        <div className="justify-content-start">
                          Total Pages: {totalPages}
                        </div>

                        <div>
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={previousPage}
                            disabled={currentPage === 0}
                          >
                            <FontAwesomeIcon icon={faChevronLeft} />
                          </button>

                          {getPageNumbers().map((pageNumber) => (
                            <button
                              key={pageNumber}
                              onClick={() => goToPage(pageNumber - 1)}
                              className={`border px-2 py-1 ${currentPage + 1 === pageNumber ? 'bg-blue-500 text-white' : ''}`}
                            >
                              {pageNumber}
                            </button>
                          ))}

                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={nextPage}
                            disabled={
                              currentPage === prescriptionData.length - 1
                            }
                          >
                            <FontAwesomeIcon icon={faChevronRight} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showPrintComponent && (
        <div
          className={`fixed inset-0  text-black ${
            showPrintComponent ? "backdrop-filter " : ""
          } transition-all duration-300 ${
            showPrintComponent ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <div className="flex items-center justify-center h-screen">
            <div
              className="bg-white p-4 rounded shadow-lg transition-transform duration-300 transform scale-100 border-2 border-blue-500 max-h-full overflow-auto"
              style={{ width: "1200px" }}
            >
              <div className="float-right">
                <h5 className="modal-title"></h5>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={handleClosePrint}
                  className="text-red-500 cursor-pointer float-right" style={{fontSize:'20px'}}
                />
              </div>

              <PrintComponent prescriptionData={prescriptionData} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PrescriptionHistory;