import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Login from "./components/login";
import Sidebar from "./components/sidebar";
import Doctor from "./components/doctor";
import CheckboxApp from "./components/patientregistration";

import './index.css'


const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/sidebar" component={Sidebar}/>

        <Route exact path="/patientregister" component={CheckboxApp} />

        <Route path="/doctor" component={Doctor} />
        
      </Switch>
    </Router>
  );
};

export default App;
