import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import config from "../config";

import dentalImage from "../logo/try.jpg";
import CheckboxApp from "./patientregistration";
import Doctor from "./doctor";
import Appointment from "./appointment";
import logoImage from "../logo/logo.jpeg";
import profileImg from '../logo/profile.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faBars } from "@fortawesome/free-solid-svg-icons";

const UserProfile = ({ user, onLogout }) => {
  const history = useHistory();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleLogout = async () => {
    const logoutEventData = {
      userId: user.user.user_id,
      userFirstName: user.user.user_first_name,
      userLastName: user.user.user_last_name,
      userRole: user.user.user_role,
    };
    setIsButtonDisabled(true);
    try {
      const response = await fetch(`${config.apiUrl}/user/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(logoutEventData),
      });

      const result = await response.json();

      if (response.status === 200) {
        localStorage.removeItem("user");
        history.push("/");
        window.location.reload();
        setIsButtonDisabled(false);
      } else {
        console.error("Error logging logout event:", result.message);
      }
    } catch (error) {
      console.error("Error logging logout event:", error.message);
    }
  };

  return (
    <div className="flex-grow-0 font-serif">
      <div className="flex items-center">
        <div className="leading-5">
          <h6 className="font-bold">
            <p>{user ? ` ${user.user.user_role}` : "Guest"}</p>
          </h6>
          <p>{` ${user.user.user_first_name} ${user.user.user_last_name} `}</p>
        </div>
        <img
          src={profileImg}
          alt="Profile"
          className="w-14 h-14 mr-1 rounded-full "
        />
        <div className="mt-2">
          <button
            className="bg-transparent border-none focus:outline-none"
            onClick={handleLogout}
            disabled={isButtonDisabled}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
          </button>
        </div>
      </div>
    </div>
  );
};

const DropdownMenu = ({ handleComponentClick, user }) => {
  return (
    <div className="absolute right-0 mt-2 bg-white rounded-md overflow-hidden shadow-xl">
      <div
        className="py-1"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
         <button
          className="block px-4 py-2 text-sm mb-3 text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full"
          role="menuitem"
          onClick={() => handleComponentClick("home")}
        >
          Home
        </button>
          <button
          className="block px-4 py-2 text-sm mb-3 text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full"
          role="menuitem"
          onClick={() => handleComponentClick("registration")}
        >
          Registration
        </button>
       
        <button
          className="block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full"
          role="menuitem"
          onClick={() => handleComponentClick("doctor module")}
        >
          Doctor Module
        </button>
        <button
          className="block px-2 py-2 text-sm text-gray-700  h-20 rounded hover:bg-gray-100 hover:text-gray-900 w-full"
          role="menuitem"
          onClick={() => handleComponentClick("appointment")}
        >
          Appointment
        </button>
      
      </div>
    </div>
  );
};


const HomePage1 = () => {
  const [showContent, setShowContent] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [showToggleButton, setShowToggleButton] = useState(true); 
  const dropdownRef = useRef(null);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  const handleComponentClick = (component) => {
    setActiveComponent(component === "home" ? "" : component);
    setShowToggleButton(true);
    setIsDropdownOpen(false); 
    if (
      component === "home" ||
      component === "registration" ||
      component === "doctor module" ||
      component === "appointment"
    )  {
      setShowToggleButton(true);
    }
  };

  const handleHistoryButtonClick = () => {
    setShowToggleButton(false);
  };

  const handleToggleClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  
  const handlesubmit =()=>{
    setShowToggleButton(false);
  }
  const handleSearchClick =()=>{
    setShowToggleButton(false);
  }
  
  const handleToggleButtonClick = () => {
    setShowToggleButton(true);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUser(userData);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ fontFamily: 'serif' }}>
      <style>
        {`
        @media (max-width:1000px){
          .running-text-container {
            width: 100%; 
left: 0;  
          }

        .running-text {
            white-space: nowrap;
            color: #3498db; 
            font-size:13px; 
            animation: runningText 20s linear infinite; 
          }

          @keyframes runningText {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-150%);
            }
          }
        }
          @keyframes fadeIn {
            0% { opacity: 0; }
            100% { opacity: 1; }
          }

          .fadeIn { animation: fadeIn 0.5s ease-in-out forwards; }

          @keyframes scaleUp {
            0% { transform: scale(0); opacity: 0; }
            100% { transform: scale(1); opacity: 1; }
          }

          .scaleUp { animation: scaleUp 1s ease-in-out forwards; }
        `}
      </style>
<div className="bg-white border border-b ">
<div className="d-flex h-full justify-content-between align-items-center ">
        <div className="d-flex align-items-center ">
          <img
            src={logoImage}
            alt="Profile"
            style={{
              width: "70px",
              height: "60px",
              marginRight: "5px",
            }}
          />
          <div>
            <h1
              className="font-bold text-2xl"
              style={{
                background: "linear-gradient(45deg, #3498db, #2ecc71)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              LINGA DENTAL CARE
            </h1>
            
          </div>
          
        </div>
      

        <div style={{ marginRight: "35px" }}>
          {user && <UserProfile user={user} />}
        </div>
      </div>
<div className="running-text-container">
            <div className="running-text">
            Sri Meenakshi Complex, Alaguraja Nagar,Alagar Kovil Main Road, Madurai -625007.
            </div>
          </div>
</div>
     
   

      <div className="flex text-white  relative" >
        <div className="relative" style={{width:'90%', height:'600px'}} >
          {activeComponent && (
            
            <div className="absolute inset-0 flex justify-center items-center">
             {activeComponent === "registration" && (
              <CheckboxApp onHistoryButtonClick={handleHistoryButtonClick} 
              onToggleButtonClick={handleToggleButtonClick}
              onPrintButtonClick={handleSearchClick}/>
            )}
              {activeComponent === "doctor module" && (
              <Doctor  onHistoryButtonClick={handleHistoryButtonClick} 
              onToggleButtonClick={handleToggleButtonClick}
              onPrintButtonClick={handlesubmit}/>
              )}
              {activeComponent === "appointment" && <Appointment />}
        
            </div>
          )}

          {/* Your background image or content */}
          {!activeComponent && (
            <img
              src={dentalImage}
              alt="Attractive Look"
              className={`absolute inset-0 w-full h-full`}
              style={{
                backgroundColor: showContent ? "white" : "white",
                opacity: imageLoaded ? 1 : 0,
                transition: "opacity 0.5s ease",
                maskImage:
                  "linear-gradient(to left, transparent, black 20%, black 90%, transparent)",
                WebkitMaskImage:
                  "linear-gradient(to left, transparent, black 20%, black 80%, transparent)",
              }}
              onLoad={handleImageLoad}
            />
          )}
        </div>

        {/* Show the faBars icon in a rounded shape */}
     
        <div className="absolute top-4 right-4" ref={dropdownRef}>
        {showToggleButton && (
          <button
            className="text-white rounded-full hover:bg-blue-600"
            style={{
              width: "40px",
              height: "40px",
              background: "linear-gradient(to bottom right, #87CEEB, #008080)",
              boxShadow: "0px 0px 10px 5px rgb(94, 183, 203)",
            }}
            onClick={handleToggleClick}
          >
            <FontAwesomeIcon icon={faBars} />
            
          </button>
        )}
          {isDropdownOpen && (
            <DropdownMenu
              handleComponentClick={handleComponentClick}
            />
          )}
   
        </div>

      </div>
    </div>
  );
};

export default HomePage1;
