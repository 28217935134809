import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHistory,
  faSearch,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTimes,
  faPrescriptionBottleAlt,
} from "@fortawesome/free-solid-svg-icons";
import config from "../config";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PrescriptionHistory from "./pdfcode";

const PrintScreen = ({
  patientDetails,
  prescriptionData,
  onClose,
  selectedDate,
}) => {
  const componentRef = useRef(null);
  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintClick = () => {
    handlePrint();
  };

  return (
    <div className="text-black ">
      <style>
        {`
          .printscreen {
            border: 1px solid grey;
            background-image: url('../Assets/template.jpg');
            background-size: 205mm 290mm;
            background-repeat: no-repeat;
            background-position: center;
            height: 290mm;
            width: 205mm;
            position: relative; /* Make the position relative */
          }
          
          @media (max-width: 600px) {
            .printscreen {
              border: 1px solid grey;
              background-image: url('../Assets/template.jpg');
              background-size: 100% 100%; /* Cover the entire container */
              background-repeat: no-repeat;
              background-position: center;
              height: 90vh;
              width: 90vw;
            }
          
            .registration-content {
              position: absolute;
              top: 26%; 
              left: 8%; 
              width: 60%;
              font-size: 8px;
            }
          
          }
          
          .registration-content {
            position: absolute;
            top: 26%; /* Adjust as needed */
            left: 6%; /* Adjust as needed */
            width: 90%; /* Adjust as needed */
          }
          /* Add any other necessary styles */
        `}
      </style>

      <div className="flex justify-end">
        <FontAwesomeIcon
          icon={faTimes}
          onClick={onClose}
          className="text-red-500 cursor-pointer size-6"
        />
      </div>

      <div
        className="printscreen border border-gray-300 rounded-lg "
        ref={componentRef}
      >
        <div className="registration-content">
          <div className=" mb-1 ">
            <p>
              <strong>To:</strong>
            </p>
         
            <p>{patientDetails.patient_name}</p>
            <p>
              {patientDetails.age}/{patientDetails.gender}
            </p>
            <p>Madurai, Tamil Nadu</p>
            <p>Ph No -{patientDetails.Mobile_number}.</p>
            <div  className="float-right mr-5">
            <p>Patient Name: {patientDetails.patient_name}</p>
            <p>UHID: {patientDetails.uhid}</p>
            </div>
         
          </div>
          
          <div className="">
       
            <table className="w-full border-collapse border border-gray-300 ">
              <thead
                className="text-center text-white"
                style={{ background: "teal" }}
              >
                <tr>
                  <th className="text-center p-2  border border-gray-300">
                    S.No
                  </th>
                  <th className="text-center py-2 border border-gray-300">
                    Medicine Name & Dosage
                  </th>
                  <th className="text-center py-2 border border-gray-300">
                    Brand Name
                  </th>
                  <th className="text-center py-2 border border-gray-300">
                    Frequency
                  </th>
                  <th className="text-center py-2 border border-gray-300">
                    Instruction
                  </th>
                  <th
                    colSpan="4"
                    className="text-center py-2 border border-gray-300"
                  >
                    Time Schedule
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th className="text-center py-2 border border-gray-300">
                    Morning
                  </th>
                  <th className="text-center py-2 border border-gray-300">
                    Afternoon
                  </th>
                  <th className="text-center py-2 border border-gray-300">
                    Evening
                  </th>
                  <th className="text-center py-2 border border-gray-300">
                    Night
                  </th>
                </tr>
              </thead>
              <tbody>
                {prescriptionData.length > 0 ? (
                  prescriptionData.map((prescription, index) => (
                    <tr key={index}>
                      <td className="text-center  border border-gray-300">
                        {index + 1}
                      </td>
                      <td
                        className="text-center  border border-gray-300"
                        style={{ maxWidth: "200px", wordWrap: "break-word" }}
                      >
                        {prescription.name}
                      </td>
                      <td
                        className="text-center  border border-gray-300"
                        style={{ maxWidth: "200px", wordWrap: "break-word" }}
                      >
                        {prescription.brandname}
                      </td>
                      <td className="text-center  border border-gray-300">
                        {prescription.frequency}
                      </td>
                      <td className="text-center  border border-gray-300">
                        {prescription.instruction}
                      </td>
                      <td className="text-center border ">
                        {prescription.morning ? "1" : "0"}
                      </td>
                      <td className="text-center border ">
                        {prescription.afternoon ? "1" : "0"}
                      </td>
                      <td className="text-center border ">
                        {prescription.evening ? "1" : "0"}
                      </td>
                      <td className="text-center border ">
                        {prescription.night ? "1" : "0"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center py-2">
                      No prescription is added.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="float-right mt-4">
            <p>
              <strong>Follow-Up Date:</strong>{" "}
              {selectedDate.toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-4 pl-12">
        <button
          onClick={handlePrintClick}
          className="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-12"
          style={{ backgroundColor: "teal" }}
        >
          Print
        </button>
      </div>
    </div>
  );
};

const Doctor = ({
  onHistoryButtonClick,
  onToggleButtonClick,
  onPrintButtonClick,
}) => {
  const [isprescription, setIsprescription] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [uhid, setUHID] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);
  const [historyData, setHistorydata] = useState([]);
  const [selectedPrescription, setSelectedPrescription] = useState([]);
  const [viewprescription, setViewprescription] = useState(false);
  const [medicalAffiction, setMedicalAffiction] = useState("");
  const [treatmentGiven, setTreatmentGiven] = useState("");
  const [treatmentFees, setTreatmentFees] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isPrintTemplateVisible, setIsPrintTemplateVisible] = useState(false);
  const [filteredHistoryData, setFilteredHistoryData] = useState([]);
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);
  const [showNotFoundMessage1, setShowNotFoundMessage1] = useState(false);

  const [patientDetails, setPatientDetails] = useState({
    patient_name: "",
    age: "",
    gender: "",
    Mobile_number: "",
  });

  const [formData, setFormData] = useState({
    patientName: "",
    uhid: "",
    age: "",
    mobileNumber: "",
    doctor: "Dr.  R. Vinoth Kumar (BDS)",

    date: "",
    prescription: "",
    medicalaffiction: "",
    treatmentgiven: "",
    treatmentFees: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showRedBorders, setShowRedBorders] = useState(false);
  const [uhidOptions, setUHIDOptions] = useState([]);

  const closeModal = () => {
    resetFormFields();
    setIsPrintTemplateVisible(false);
    setIsprescription(false);
    onToggleButtonClick();
  };

  const resetFormFields = () => {
    setUHID("");
    setTreatmentGiven("");
    setTreatmentFees("");
    setMedicalAffiction("");
    setPatientDetails({
      patient_name: "",
      age: "",
      gender: "",
      Mobile_number: "",
    });
    setPrescriptionData([]);
  };

  const handleSubmit = async () => {
    if (
      !uhid ||
      !patientDetails.patient_name ||
      !patientDetails.gender ||
      !patientDetails.age ||
      !patientDetails.Mobile_number ||
      !medicalAffiction ||
      !treatmentGiven ||
      !treatmentFees ||
      !selectedDate
    ) {
      setShowRedBorders(true);
      setTimeout(() => {
        setShowRedBorders(false);
      }, 2000);
      return;
    }

    try {
      const response = await axios.post(
        `${config.apiUrl}/doctor/saveprescription`,
        {
          uhid,
          patientName: patientDetails.patient_name,
          mobileNumber: patientDetails.Mobile_number,
          gender: patientDetails.gender,
          age: patientDetails.age,
          doctorName: "Dr. R. Vinoth Kumar (BDS)",
          date: currentDate,
          medicalaffiction: medicalAffiction,
          treatmentgiven: treatmentGiven,
          treatmentFees: treatmentFees,
          followupdate: selectedDate,
          prescription: prescriptionData,
        }
      );

      if (response.status === 200) {
        console.log(response.data.message);
        // Always set isPrintTemplateVisible to true
        setIsPrintTemplateVisible(true);
        onPrintButtonClick();
      } else {
        console.error("Failed to save prescription:", response.data.error);
      }
    } catch (error) {
      console.error("Error saving prescription:", error.message);
    }
  };

  useEffect(() => {
    fetchUHIDs();
  }, []);

  const fetchUHIDs = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/doctor/patientregistration/listuhids`
      );
      console.log("response", response);
      setUHIDOptions(response.data);
    } catch (error) {
      console.error("Error fetching UHIDs:", error);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedMedicines = [...prescriptionData];
    updatedMedicines[index][field] = value;
    setPrescriptionData(updatedMedicines);

    const updatedEmptyFields = [...emptyFields];
    if (value.trim() === "" && !updatedEmptyFields.includes(index)) {
      updatedEmptyFields.push(index); // Field is empty, add to emptyFields
    } else if (value.trim() !== "") {
      const indexOfField = updatedEmptyFields.indexOf(index);
      if (indexOfField !== -1) {
        updatedEmptyFields.splice(indexOfField, 1);
      }
    }
    setEmptyFields(updatedEmptyFields);
  };
  const handleCheckboxClick = (index, time) => {
    const updatedSelectedTimes = [...selectedTimes];
    updatedSelectedTimes[index] = {
      ...updatedSelectedTimes[index],
      [time]: !updatedSelectedTimes[index]?.[time],
    };
    setSelectedTimes(updatedSelectedTimes);
  };

  const handleAddMedicine = () => {
    const newMedicine = {
      brandname: "",
      name: "",
      frequency: "",
      instruction: "",
      morning: false,
      afternoon: false,
      evening: false,
      night: false,
    };
    setPrescriptionData([...prescriptionData, newMedicine]);
  };

  const handleOpenprescriptionmodal = () => {
    setIsprescription(true);
    setIsPrintTemplateVisible(false);
    onHistoryButtonClick();
  };

  const handleCloseprescriptionmodal = () => {
    setIsPrintTemplateVisible(false);
    setIsprescription(false);
    setPrescriptionData([]);
    onToggleButtonClick();
  };

  const handlePrescriptionSubmit = () => {
    const updatedEmptyFields = prescriptionData.reduce(
      (emptyFields, medicine, index) => {
        if (
          medicine.brandname === "" ||
          medicine.name === "" ||
          medicine.frequency === "" ||
          medicine.instruction === ""
        ) {
          emptyFields.push(index);
        }
        return emptyFields;
      },
      []
    );

    if (updatedEmptyFields.length > 0) {
      setShowRedBorders(true);
      setTimeout(() => {
        setShowRedBorders(false);
      }, 2000);
      return;
    }

    const isAnyCheckboxUnchecked = prescriptionData.some(
      (medicine) =>
        !(
          medicine.morning ||
          medicine.afternoon ||
          medicine.evening ||
          medicine.night
        )
    );

    if (isAnyCheckboxUnchecked) {
      setShowNotFoundMessage1(true);
      setTimeout(() => {
        setShowNotFoundMessage1(false);
      }, 1000);

      return;
    }

    setIsPrintTemplateVisible(false);
    setIsprescription(false);
  };

  const handleRemoveMedicine = (index) => {
    const updatedMedicines = [...prescriptionData];
    updatedMedicines.splice(index, 1);
    setPrescriptionData(updatedMedicines);
  };

  const handleCloseviewprescriptionmodal = () => {
    setViewprescription(false);
    resetFormFields();
  };

  const handleUHIDChange = (event) => {
    const newUHID = event.target.value;
    setUHID(newUHID);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const newSearchTimeout = setTimeout(async () => {
      try {
        const response = await axios.get(
          `${config.apiUrl}/doctor/patient/searchname?uhid=${newUHID}`
        );
        const { patient_name, age, gender, Mobile_number } = response.data;
        setPatientDetails({ patient_name, age, gender, Mobile_number });
      } catch (error) {
        console.error("Error fetching patient details:", error.message);
        setPatientDetails({
          patient_name: "",
          age: "",
          gender: "",
          Mobile_number: "",
        });
        if (error.response && error.response.status === 404) {
          setShowNotFoundMessage(true);
          setTimeout(() => {
            setShowNotFoundMessage(false);
          }, 1000);
        }
      }
    }, 2000);

    setSearchTimeout(newSearchTimeout);
  };

  const handleHistoryButtonClick = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/doctor/getprescriptions`
      );
      const prescriptionData = response.data.prescriptionData;

      if (response.status === 200) {
        setPrescriptionData(prescriptionData);
      } else {
        console.error("Non-200 response code received");
      }
    } catch (error) {
      console.error("Error fetching prescription data:", error.message);
    }

    setHistoryModalOpen(true);
    setIsPrintTemplateVisible(false);
    onHistoryButtonClick();
  };

  const handleCloseHistoryModal = () => {
    setSearchQuery("");
    setHistoryModalOpen(false);
    setFilteredHistoryData([]);
    setIsFilterClicked(false);
    onToggleButtonClick();
      resetFormFields();
  };

  const handleViewPrescription = () => {
    setViewprescription(true);
  };

  const currentDate = new Date().toISOString().slice(0, 10);

  return (
    <div className="mt-10 ml-1  flex  flex-col w-full">
      <style>
        {`
   
   @media screen and (max-width: 640px) {
    .demo {
      margin-top: 24px;
    }
  }

    @media only screen and (min-width: 1024px)  {
      .demo {
        padding: 40px;
      }
      .history{
        margin-right:320px
      }
      .prescription{
        margin-right:320px
      }
      .print{
        margin-right:320px
      }
    }
           .highlight-input {
           border: 1px solid red;
          } 
        `}
      </style>

      <div className="demo flex flex-col pt-8 pl-10 pb-2">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-2">
          <div className="md:mb-0">
            <h2 className="font-bold text-xl mb-1 ml-2 text-gray-700">
              Consultation Doctor
            </h2>
            <p className="font-bold text-gray-700 ml-2">{formData.doctor}</p>
          </div>
          <div className="md:items-center md:float-right mt-1">
            <label className="block text-sm font-bold text-gray-700">
              Date
            </label>
            <input
              type="text"
              className="mt-1 p-2 border rounded w-300 md:w-300 text-black"
              value={currentDate}
              readOnly
            />
          </div>
        </div>

        <div
          className="ml-auto flex md:items-center  items-center bg-blue-900 hover:bg-green-600 text-white font-bold py-2 px-4 rounded curser-pointer"
          onClick={handleHistoryButtonClick}
        >
          <button>
            {" "}
            <FontAwesomeIcon icon={faHistory} className="text-white mr-2" />
            History
          </button>
        </div>
        <div className="flex space-x-4 mb-2">
          <div className="flex-1">
            <b>
              <label className="block text-sm font-bold text-gray-700">
                UHID
              </label>
            </b>
            <input
              type="text"
              placeholder="UHID"
              list="uhidOptions"
              value={uhid.toUpperCase()}
              style={{
                padding: "2px",
                border: `1px solid   ${
                  showRedBorders && !uhid ? "red" : "#d1d5db"
                }`,
                borderRadius: "4px",
                color: "black",
                width: "100%",
                height: "38px",
              }}
              onChange={handleUHIDChange}
            />
            <datalist id="uhidOptions">
              {uhidOptions.map((option, index) => (
                <option key={index} value={option} />
              ))}
            </datalist>
          </div>
          <div className="flex-1">
            <label className="block text-sm font-bold text-gray-700">
              Patient Name
            </label>
            <input
              type="text"
              placeholder="Patient name"
              style={{
                padding: "2px",
                border: `1px solid   ${
                  showRedBorders && !patientDetails.patient_name
                    ? "red"
                    : "#d1d5db"
                }`,
                borderRadius: "4px",
                width: "100%",
                color: "black",
                height: "38px",
              }}
              value={patientDetails.patient_name}
              readOnly
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6">
          <div className="space-y-4">
            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="block text-sm font-bold text-gray-700">
                  Gender
                </label>
                <input
                  type="text"
                  placeholder="Gender"
                  style={{
                    padding: "2px",
                    border: `1px solid   ${
                      showRedBorders && !patientDetails.gender
                        ? "red"
                        : "#d1d5db"
                    }`,
                    borderRadius: "4px",
                    width: "100%",
                    color: "black",
                    height: "38px",
                  }}
                  value={patientDetails.gender}
                  readOnly
                />
              </div>
              <div className="flex-1">
                <b>
                  <label className="block text-sm font-bold text-gray-700">
                    Age
                  </label>
                </b>
                <input
                  type="text"
                  placeholder="Age"
                  style={{
                    padding: "2px",
                    border: `1px solid   ${
                      showRedBorders && !patientDetails.age ? "red" : "#d1d5db"
                    }`,
                    borderRadius: "4px",
                    width: "100%",
                    color: "black",
                    height: "38px",
                  }}
                  value={patientDetails.age}
                  readOnly
                />
              </div>
            </div>

            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="block text-sm font-bold text-gray-700">
                  Mobile Number
                </label>
                <input
                  type="text"
                  placeholder="Mobile Number"
                  style={{
                    padding: "2px",
                    border: `1px solid   ${
                      showRedBorders && !patientDetails.Mobile_number
                        ? "red"
                        : "#d1d5db"
                    }`,
                    borderRadius: "4px",
                    width: "100%",
                    color: "black",
                    height: "38px",
                  }}
                  value={patientDetails.Mobile_number}
                  readOnly
                />
              </div>
              <div className="flex-1">
                <label className={`block text-sm font-bold text-gray-700`}>
                  Current Dental Challenge
                </label>
                <input
                  type="textarea"
                  placeholder="Enter Current Problem"
                  style={{
                    padding: "2px",
                    border: `1px solid  ${
                      showRedBorders && !medicalAffiction ? "red" : "#d1d5db"
                    }`,
                    borderRadius: "4px",
                    color: "black",
                    width: "100%",
                    height: "38px",
                    wordWrap: "break-word"
                  }}
                  pattern="[0-9]+([.][0-9]+)?"
                  title="Enter a value"
                  value={medicalAffiction}
                  onChange={(e) => setMedicalAffiction(e.target.value)}
                />
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="flex-1">
                <label className={`block text-sm font-bold text-gray-700`}>
                  Treatment Given
                </label>
                <input
                  type="textarea"
                  placeholder="Enter sloution"
                  style={{
                    padding: "2px",
                    border: `1px solid   ${
                      showRedBorders && !treatmentGiven ? "red" : "#d1d5db"
                    }`,
                    borderRadius: "4px",
                    color: "black",
                    width: "100%",
                    height: "44px"
                  }}
                  className="rounded"
                  pattern="[0-9]+([.][0-9]+)?"
                  title="Enter a value"
                  value={treatmentGiven}
                  onChange={(e) => setTreatmentGiven(e.target.value)}
                />
              </div>
              <div className="flex-1">
                <label className="block text-sm font-bold text-gray-700">
                  Treatment Fees
                </label>
                <input
                  type="text"
                  placeholder="Enter a Treatment Fees"
                  style={{
                    padding: "2px",
                    border: `1px solid ${
                      showRedBorders && !treatmentFees ? "red" : "#d1d5db"
                    }`,
                    borderRadius: "4px",
                    color: "black",
                    width: "100%",
                    height: "44px",
                  }}
                  className="rounded"
                  title="Enter a Treatment Fees"
                  value={treatmentFees}
                  onChange={(e) => setTreatmentFees(e.target.value)}
                  onKeyPress={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (!regex.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row md:items-center md:justify-start">
              <div className="mb-4 md:mb-0 md:mr-4 w-full md:w-auto md:max-w-xs lg:max-w-md">
                <label className="block text-sm font-bold text-gray-700">
                  Follow-up Date
                </label>
                <div className="relative p-2 border rounded bg-white text-black">
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="MM /dd /yyyy"
                    style={{
                      width: "100%",
                      height: "38px",
                      color: "black",
                      border: `1px solid ${
                        showRedBorders && !patientDetails.selectedDate
                          ? "red"
                          : "#d1d5db"
                      }`,
                    }}
                  />
                </div>
              </div>

              <button
                onClick={handleOpenprescriptionmodal}
                className="flex items-center text-white py-2 px-4 rounded-md bg-blue-900"
              >
                <FontAwesomeIcon
                  icon={faPrescriptionBottleAlt}
                  className="mr-2"
                />
                <b>Prescription</b>
              </button>
            </div>
          </div>

          {isprescription && (
            <div
              className={`prescription fixed inset-0 overflow-y-auto  ${
                isprescription
                  ? "backdrop-filter backdrop-blur-sm backdrop-blur-lg"
                  : ""
              } transition-all duration-300 ${
                isprescription ? "opacity-100" : "opacity-0 pointer-events-none"
              }`}
            >
              <div className="flex items-center justify-center h-screen">
                <div
                  className="bg-white p-4 rounded shadow-lg transition-transform duration-300 transform scale-100 border-2 border-blue-500 max-h-full overflow-auto"
                  style={{
                    top: "4%",
                    height: "83%",
                    width: "95%",
                  }}
                >
                  <div className="flex justify-between items-center ">
                    <h2 className="text-3xl font-bold text-blue-500">
                      Prescription
                    </h2>
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={handleCloseprescriptionmodal}
                      className="text-red-500 cursor-pointer"
                      size="lg"
                    />
                  </div>
                  <div className="overflow-auto" style={{ maxHeight: "400px" }}>
                    <table className="table-auto w-full text-black  border border-collapse border-white-300 ">
                      <thead className="text-center  bg-blue-900 text-white">
                        <tr>
                          <th className="text-center px-4 py-2 border-2">
                            Medicine Name & Dosage
                          </th>
                          <th className="text-center px-4 py-2 border-2">
                            Brand
                          </th>
                          <th className="text-center px-6 py-2 border-2">
                            No. of Days
                          </th>
                          <th className="text-center px-4 py-2 border-2">
                            Instruction
                          </th>
                          <th
                            colSpan="4"
                            className="text-center px-4 py-2 border-2"
                          >
                            Time Schedule
                          </th>
                          <th className="text-center px-4 py-2 border-2">
                            Action
                          </th>
                        </tr>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th className="text-center px-1 py-2 border-2">
                            Morning
                          </th>
                          <th className="text-center px-1 py-2 border-2">
                            Afternoon
                          </th>
                          <th className="text-center px-1 py-2 border-2">
                            Evening
                          </th>
                          <th className="text-center px-1 py-2 border-2">
                            Night
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {prescriptionData.map((prescription, index) => (
                          <tr key={index}>
                            <input
                              type="text"
                              className={`mt-1 p-1 border-2 rounded-md w-full ${
                                showRedBorders &&
                                prescriptionData[index].name.trim() === ""
                                  ? "highlight-input"
                                  : ""
                              }`}
                              list="medicineNames"
                              placeholder="Enter Medicine Name"
                              value={prescriptionData[index].name}
                              onChange={(e) =>
                                handleInputChange(index, "name", e.target.value)
                              }
                            />
                            <td>
                              <input
                                type="text"
                                className={`mt-1 p-1 border-2 rounded-md w-full ${
                                  showRedBorders &&
                                  prescriptionData[index].brandname.trim() ===
                                    ""
                                    ? "highlight-input"
                                    : ""
                                }`}
                                list="brandname"
                                placeholder="Enter brand Name"
                                value={prescriptionData[index].brandname || ""}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "brandname",
                                    e.target.value
                                  )
                                }
                              />
                            </td>

                            <td>
                              <input
                                type="text"
                                className={`mt-1 p-1 border-2 rounded-md w-full ${
                                  showRedBorders &&
                                  prescriptionData[index].frequency.trim() ===
                                    ""
                                    ? "highlight-input"
                                    : ""
                                }`}
                                list="Frequency"
                                placeholder="Enter Frequency"
                                value={prescriptionData[index].frequency}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "frequency",
                                    e.target.value
                                  )
                                }
                                onKeyPress={(e) => {
                                  const regex = /^[0-9\b]+$/;
                                  if (!regex.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </td>

                            <td>
                              <select
                                className={`mt-1 p-1 border-2 rounded-md w-full ${
                                  showRedBorders &&
                                  prescriptionData[index].instruction.trim() ===
                                    ""
                                    ? "highlight-input"
                                    : ""
                                }`}
                                style={{ backgroundColor: "white" }}
                                value={prescription.instruction}
                                onChange={(e) => {
                                  const updatedMedicines = [
                                    ...prescriptionData,
                                  ];
                                  updatedMedicines[index].instruction =
                                    e.target.value;
                                  setPrescriptionData(updatedMedicines);
                                }}
                              >
                                <option value="">Select Instruction</option>
                                <option value="Before meal">Before meal</option>
                                <option value="After meal">After meal</option>
                              </select>
                            </td>
                            <td
                              className="text-center cursor-pointer"
                              onClick={() =>
                                handleCheckboxClick(index, "morning")
                              }
                            >
                              <input
                                type="checkbox"
                                checked={prescription.morning}
                                onChange={(e) => {
                                  const updatedMedicines = [
                                    ...prescriptionData,
                                  ];
                                  updatedMedicines[index].morning =
                                    e.target.checked;
                                  setPrescriptionData(updatedMedicines);
                                }}
                              />
                            </td>
                            <td
                              className="text-center cursor-pointer"
                              onClick={() =>
                                handleCheckboxClick(index, "afternoon")
                              }
                            >
                              <input
                                type="checkbox"
                                checked={prescription.afternoon}
                                onChange={(e) => {
                                  const updatedMedicines = [
                                    ...prescriptionData,
                                  ];
                                  updatedMedicines[index].afternoon =
                                    e.target.checked;
                                  setPrescriptionData(updatedMedicines);
                                }}
                              />
                            </td>
                            <td
                              className="text-center cursor-pointer"
                              onClick={() =>
                                handleCheckboxClick(index, "evening")
                              }
                            >
                              <input
                                type="checkbox"
                                checked={prescription.evening}
                                onChange={(e) => {
                                  const updatedMedicines = [
                                    ...prescriptionData,
                                  ];
                                  updatedMedicines[index].evening =
                                    e.target.checked;
                                  setPrescriptionData(updatedMedicines);
                                }}
                              />
                            </td>
                            <td
                              className="text-center cursor-pointer"
                              onClick={() =>
                                handleCheckboxClick(index, "night")
                              }
                            >
                              <input
                                type="checkbox"
                                checked={prescription.night}
                                onChange={(e) => {
                                  const updatedMedicines = [
                                    ...prescriptionData,
                                  ];
                                  updatedMedicines[index].night =
                                    e.target.checked;
                                  setPrescriptionData(updatedMedicines);
                                }}
                              />
                            </td>
                            <td
                              className="cursor-pointer text-center"
                              onClick={() => handleRemoveMedicine(index)}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                style={{ color: "red", fontSize: "1.2rem" }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button
                      onClick={handleAddMedicine}
                      className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md"
                    >
                      Add Medicine
                    </button>
                  </div>
                  <div className="text-end">
                    <button
                      onClick={handlePrescriptionSubmit}
                      className="mt-4  bg-blue-500 hover:bg-green-500 text-white py-2 px-4 rounded-md"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end mt-1">
          <div>
            {isHistoryModalOpen && (
              <div
                className={`history fixed inset-0 text-black ${
                  isHistoryModalOpen ? "backdrop-filter backdrop-blur-sm" : ""
                } transition-all duration-300 ${
                  isHistoryModalOpen
                    ? "opacity-100"
                    : "opacity-0 pointer-events-none"
                }`}
              >
                <div className="flex items-center justify-center h-screen">
                  <div
                    className="bg-white p-4 rounded shadow-lg transition-transform duration-300 transform scale-100 border-2 border-blue-500 max-h-full "
                    style={{
                      height: "93%",
                      width: "90%",
                    }}
                  >
                    <div className="float-right">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={handleCloseHistoryModal}
                        className="text-red-500 mb-20 cursor-pointer"
                        size="lg"
                      />
                    </div>
                    {prescriptionData !== null ? (
                      <PrescriptionHistory props={{ prescriptionData }} />
                    ) : (
                      <p>Loading...</p>
                    )}
                  </div>
                </div>
              </div>
            )}
            <button
              className="bg-blue-900 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-4"
              onClick={() => {
                resetFormFields();
              }}
            >
              Cancel
            </button>
            <button
              className="bg-blue-900 hover:bg-green-700 text-white font-bold py-2 px-4 rounded "
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      {isPrintTemplateVisible && (
        <div
          className={`print fixed inset-0 overflow-y-auto ${
            isPrintTemplateVisible ? "backdrop-filter backdrop-blur-sm" : ""
          } transition-all duration-300 ${
            isPrintTemplateVisible
              ? "opacity-100"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <div className="flex items-center justify-center h-screen">
            <div
              className={`try bg-white p-4 w-1/2 md:w-1/2 lg:w-3/4 rounded shadow-lg transition-transform duration-300 transform ${
                isPrintTemplateVisible ? "scale-100" : "scale-0"
              } border-2 border-blue-500 max-h-full overflow-y-auto `}
              style={{
                scrollbarWidth: "thin",
                scrollbarColor: "rgba(0, 0, 0, 0.5) transparent",
                top: "3%",
                height: "100%",
                width: "92%",
              }}
            >
              <PrintScreen
                patientDetails={{ ...patientDetails, uhid: uhid }}
                selectedDate={selectedDate}
                prescriptionData={prescriptionData}
                onClose={() => {
                  closeModal();
                }}
              />
            </div>
          </div>
        </div>
      )}

      {showNotFoundMessage && (
        <div
          className="fixed left-1/3 transform -translate-x-1/2 -translate-y-1/2 bg-gradient-to-r from-purple-600 to-blue-600 text-white p-2 rounded"
          style={{ top: "130px" }}
        >
          No patient found for the given UHID.
        </div>
      )}
      {showNotFoundMessage1 && (
        <div
          className="fixed left-2/3 transform -translate-x-1/2 -translate-y-1/2 bg-gradient-to-r from-purple-600 to-blue-600 text-white p-2 rounded"
          style={{ top: "90px" }}
        >
          Please select at least one checkbox.{" "}
        </div>
      )}
    </div>
  );
};

export default Doctor;
