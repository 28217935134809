import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Alert, Modal } from "react-bootstrap";
import axios from "axios";
import config from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faTimes,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import AppointmentsCalendar from "./appointmentcalendar";

function Appointment() {
  const [formData, setFormData] = useState({
    doctorName: "Dr.  R. Vinoth Kumar (BDS)",
    patientName: "",
    mobileNo: "",
    age: "",
    gender: "",
    medicalAffiliation: "",
    selectedDate: "",
    selectedTime: "",
    uhid: "",
  });
  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);
  const [uhid, setUHID] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [highlightedFields, setHighlightedFields] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uhidOptions, setUHIDOptions] = useState([]);

  const [submitting, setSubmitting] = useState(false); // Add state for button disabling
  const [patientDetails, setPatientDetails] = useState({
    patient_name: "",
    age: "",
    gender: "",
    Mobile_number: "",
  });
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setUHID([]);
  };

  const handleAppointmentClick = () => {
    openModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setHighlightedFields((prevFields) =>
      prevFields.filter((field) => field !== name)
    );
    setShowNotFoundMessage(false);
  };

  useEffect(() => {
    if (formData.selectedDate) {
      fetchAvailableTimeSlots();
    }
  }, [formData.selectedDate]);

  const fetchAvailableTimeSlots = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/appointment/availableTimeSlots`,
        {
          params: { selectedDate: formData.selectedDate },
        }
      );
      setAvailableTimeSlots(response.data);
    } catch (error) {
      console.error("Error fetching available time slots:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData, "formData");

    const requiredFields = [
      "uhid",
      "patientName",
      "mobileNo",
      "age",
      "gender",
      "medicalAffiliation",
      "selectedDate",
      "selectedTime",
    ];
    console.log(formData, "formData");
    const emptyFields = requiredFields.filter((field) => !formData[field]);
    console.log(formData, "formData");
    if (emptyFields.length > 0) {
      console.log(emptyFields.length, "formData");
      setHighlightedFields(emptyFields);

      setTimeout(() => {
        setHighlightedFields([]);
      }, 3000);
      return;
    }
    // console.log(formData,"formData")
    // Check if selected date is not a past date
    const currentDate = new Date().toISOString().split("T")[0];
    if (formData.selectedDate < currentDate) {
      alert("Please select a future date.");
      return;
    }
    console.log(formData, "formData");
    try {
      setSubmitting(true);

      const response = await axios.post(
        `${config.apiUrl}/appointment/appointment`,
        formData
      );
      setShowSuccessMessage(true);
      setFormData({
        doctorName: "Dr.  R. Vinoth Kumar (BDS)",
        patientName: "",
        uhid: "",
        mobileNo: "",
        age: "",
        gender: "",
        medicalAffiliation: "",
        selectedDate: "",
        selectedTime: "",
      });
      setUHID("");
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmitting(false);
    } finally {
      setSubmitting(false); // Re-enable submit button regardless of success or failure
    }
  };

  const handlecancel = () => {
    setFormData({
      doctorName: "Dr.  R. Vinoth Kumar (BDS)",
      patientName: "",
      mobileNo: "",
      age: "",
      gender: "",
      medicalAffiliation: "",
      selectedDate: "",
      selectedTime: "",
      uhid: "",
    });
    setUHID("");
  };

  const handleUHIDChange = (event) => {
    const newUHID = event.target.value;
    setUHID(newUHID);

    // Clear any existing timeout to avoid multiple requests
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new timeout to delay the API request by 2 seconds
    const newSearchTimeout = setTimeout(async () => {
      try {
        const response = await axios.get(
          `${config.apiUrl}/doctor/patient/searchname?uhid=${newUHID}`
        );
        const { patient_name, age, gender, Mobile_number } = response.data;
        console.log("uhid", newUHID);
      
        setFormData({
          ...formData,
          uhid: newUHID,
          patientName: patient_name,
          age: age.toString(),

          gender: gender,
          mobileNo: Mobile_number,
        });
      } catch (error) {
        console.error("Error fetching patient details:", error.message);
        setFormData({
          doctorName: "Dr.  R. Vinoth Kumar (BDS)",
          patientName: "",
          mobileNo: "",
          age: "",
          gender: "",
          medicalAffiliation: "",
          selectedDate: "",
          selectedTime: "",
          uhid: "",
        });
        if (error.response && error.response.status === 404) {
         
          setShowNotFoundMessage(true);
          
          setTimeout(() => {
            setShowNotFoundMessage(false);
          }, 2000);
        }
      }
    }, 1000); // 2 seconds delay

    setSearchTimeout(newSearchTimeout);
  };

  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    fetchUHIDs();
  }, []);

  const fetchUHIDs = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/doctor/patientregistration/listuhids`
      );
      console.log("response", response);
      setUHIDOptions(response.data);
    } catch (error) {
      console.error("Error fetching UHIDs:", error);
    }
  };

  return (
    <>
      <style>
        {`

@media screen and (min-width: 1200px) {
    .try {
        margin-left: auto;
        margin-right: 20px; 
    }
}

@media screen and (max-width: 1199px) {
    .try {
        margin-left: 300px; 
    }
}
     .highlighted {
        border: 1px solid red;
    }
    .custom-modal {
        max-width: 1000px; 
        min-height: 400px; 
    }
      

    `}
      </style>

      <div className="container text-gray-700 ">
        {showNotFoundMessage && (
          <Alert
            variant="danger"
            style={{ zIndex: 1, top: "60px", left: "35%" }}
            className="position-absolute w-30 p-1 "
          >
            No patient found for the given UHID.
          </Alert>
        )}

        <div className="  ps-4 pe-4 pb-2 ">
          <Form onSubmit={handleSubmit}>
            <Row className="mt-3">
              {/* <Col className=" text-end"><h6 className='mr-10'>Consultation Doctor  <br/></h6>
                        <strong>Dr.  R. Vinoth Kumar (BDS)</strong></Col> */}
              <Col className="text-end">
                <div className="mt-2 mb-10">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="cursor-pointer text-black text-2xl "
                    onClick={handleAppointmentClick}
                  />{" "}
                  <strong>Booked Appointment</strong>
                </div>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <Form.Group controlId="uhid">
                  <Form.Label>
                    <b>UHID</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter UHID"
                    name="uhid"
                    list="uhidOptions"
                    value={uhid}
                    onChange={handleUHIDChange}
                    className={
                      highlightedFields.includes("uhid") ? "highlighted" : ""
                    }
                  />
                  <datalist id="uhidOptions">
            {uhidOptions.map((option, index) => (
              <option key={index} value={option} />
            ))}
          </datalist>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <Form.Group controlId="patientName">
                  <Form.Label>
                    <b>Patient Name</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter patient name"
                    name="patientName"
                    value={formData.patientName}
                    onChange={handleChange}
                    className={
                      highlightedFields.includes("patientName")
                        ? "highlighted"
                        : ""
                    }
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="mobileNo">
                  <Form.Label>
                    <b>Mobile No</b>
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    pattern="[0-9]{10}"
                    placeholder="Enter mobile number"
                    name="mobileNo"
                    value={formData.mobileNo}
                    onChange={handleChange}
                    className={
                      highlightedFields.includes("mobileNo")
                        ? "highlighted"
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <Form.Group controlId="age">
                  <Form.Label>
                    <b>Age</b>
                  </Form.Label>
                  <Form.Control
                    min="0"
                    max="100"
                    placeholder="Enter age"
                    name="age"
                    value={formData.age}
                    onChange={handleChange}
                    className={
                      highlightedFields.includes("age") ? "highlighted" : ""
                    }
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="gender">
                  <Form.Label>
                    <b>Gender</b>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className={
                      highlightedFields.includes("gender") ? "highlighted" : ""
                    }
                  >
                    <option value="">Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <Form.Group controlId="selectedDate">
                  <Form.Label>
                    <b>Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={formData.selectedDate}
                    name="selectedDate"
                    onChange={handleChange}
                    min={today}
                    className={
                      highlightedFields.includes("selectedDate")
                        ? "highlighted"
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="selectedTime">
                  <Form.Label>
                    <b>Time</b>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={formData.selectedTime}
                    name="selectedTime"
                    onChange={handleChange}
                    className={
                      highlightedFields.includes("selectedTime")
                        ? "highlighted"
                        : ""
                    }
                  >
                    <option value="">Select time slot</option>
                    {availableTimeSlots.map((timeSlot, index) => (
                      <option key={index} value={timeSlot}>
                        {timeSlot}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-2" controlId="medicalAffiliation">
              <Form.Label>
                <b>Medical Affiliation</b>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter medical affiliation"
                name="medicalAffiliation"
                value={formData.medicalAffiliation}
                onChange={handleChange}
                className={
                  highlightedFields.includes("medicalAffiliation")
                    ? "highlighted"
                    : ""
                }
              />
            </Form.Group>

            <Row>
              <Col xs="auto" className="ms-auto">
                <Button
                  type="button"
                  className="bg-blue-900 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handlecancel}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  type="submit"
                  disabled={submitting}
                  className="bg-blue-900 hover:bg-green-400 text-white font-bold py-2 px-4 rounded mr-2"
                >
                  {submitting ? "Submitting..." : "Submit"}
                </Button>
              </Col>
            </Row>
          </Form>

          <Alert
            show={showSuccessMessage}
            className="alert alert-success alert-dismissible fade show"
            role="alert"
            style={{
              position: "fixed",
              top: "60px",
              left: "35%",

              background: "linear-gradient(to bottom right, #87CEEB, #008080)",
              color: "white",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0px 0px 20px 5px rgba(255, 255, 255, 1)",
              // zIndex: "9999",
              display: "block",
            }}
          >
            Appointment done successfully!
          </Alert>

          <Modal
            show={isModalOpen}
            onHide={closeModal}
            dialogClassName="custom-modal font-serif "
          >
            <FontAwesomeIcon
              icon={faTimes}
              onClick={closeModal}
              className="try text-red-500 cursor-pointer pt-4"
              size="lg"
            />
            <Modal.Header>
              <Modal.Title>Appointment Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AppointmentsCalendar></AppointmentsCalendar>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Appointment;
